import { IconButton } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

interface AIButtonProps {
  onClick: () => void;
}

function AIButton({ onClick }: AIButtonProps) {
  return (
    <IconButton onClick={onClick} sx={{ fontSize: 13 }} disableRipple>
      <AutoAwesomeIcon />
    </IconButton>
  );
}

export default AIButton;
