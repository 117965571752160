import { styled, SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { breakpoints } from '../../../utils/breakpoints';

export const dateFieldSx: SxProps<Theme> = {
  borderRadius: '2.9rem',
  backgroundColor: (theme) => theme.palette.authInputText.main,

  '& .MuiOutlinedInput-root fieldset': {
    borderColor: 'transparent',
  },

  '& .MuiOutlinedInput-root': {
    fontSize: '1.26rem',
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
      boxShadow: 'none',
    },

    [`@media (max-width: ${breakpoints.mobile})`]: {
      fontSize: '2.02rem',
    },
  },

  '& .MuiSvgIcon-root': {
    color: '#fff',
  },
};

export const InputLabelStyled = styled('label')`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.23rem;
  color: #fff;
  padding: 0 2rem;
`;

export const FormHelperTextStyled = styled(FormHelperText)(({ theme }) => ({
  fontSize: '1.23rem',
  margin: '0.5rem 0 0 1.8rem',
  fontWeight: 'bold',
  color: theme.palette.error.main,
}));
