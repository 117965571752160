import { Button } from '@mui/material';
import styled from '@emotion/styled';

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.button.main};
  color: ${({ theme }) => theme.palette.button.contrastText};
  min-width: 15rem;
  box-shadow: none;
  border-radius: 2.9rem;
  font-weight: bold;
  font-size: 1.4rem;

  &:hover {
    background-color: ${({ theme }) => theme.palette.button.dark};
  }
`;
