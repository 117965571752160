import React, { useCallback, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Description } from '../Auth.style';
import { Alert } from '../../../components';
import { useParams } from 'react-router-dom';
import { useMutationRunner } from '../../../hooks';
import { useActivateUserMutation, useResendActivationCodeMutation } from '../../../store';
import {
  ActivateUserContent,
  InputTextStyle,
  SendCodeAgainText,
  SendCodeLink,
  Text,
  UserEmail,
} from './ActivateUser.style';
import AuthTitle from '../components/auth-title/AuthTitle';
import MainButton from '../../../components/button/MainButton';
import HelpLink from '../components/help-link/HelpLink';
import { AlertSeverity } from '../../../components/alert/Alert';

interface ActivateUserForm {
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;
}

const ActivateUser = () => {
  const { email } = useParams();
  const [message, setMessage] = useState<{ type: AlertSeverity; text: string }>({
    type: AlertSeverity.Error,
    text: '',
  });
  const [resendActivationCode] = useResendActivationCodeMutation();
  const [activateUser] = useActivateUserMutation();
  const { control, handleSubmit, getValues } = useForm<ActivateUserForm>({
    mode: 'onChange', // Allows checking form validity on every change
  });
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value) || value === '') {
      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  }, []);

  const handleResendCode = useCallback(async () => {
    try {
      await resendActivationCode({ email });
      setMessage({ type: AlertSeverity.Success, text: 'A new activation code has been sent to your email.' });
    } catch (error) {
      console.error('Error resending activation code:', error);
      setMessage({ type: AlertSeverity.Error, text: 'Failed to resend activation code. Please try again later.' });
    }
  }, [email, resendActivationCode]);

  const mutationRunner = useMutationRunner<{ activationCode: string; email: string }, void>(activateUser, {
    onSuccess: (values, data) => {
      setMessage({ type: AlertSeverity.Success, text: 'User activated successfully' });
    },
    onError: (error) => {
      setMessage({ type: AlertSeverity.Error, text: 'Invalid code. Please try again.' });
    },
  });

  const onSubmit = useCallback(async () => {
    const data = getValues();
    if (Object.values(data).some((code) => !code)) {
      setMessage({ type: AlertSeverity.Error, text: 'All fields must be filled.' });
      return;
    }

    const activationCode = Object.values(data).join('');
    await mutationRunner({ activationCode, email });
  }, [getValues, mutationRunner, email]);

  return (
    <Grid container justifyContent="center">
      <AuthTitle>Verify code</AuthTitle>
      <ActivateUserContent>
        <Grid container direction="column" alignItems="flex-start" sx={{ py: 2 }}>
          <Description>We just sent you a confirmation code to</Description>
          <UserEmail>{email}</UserEmail>
        </Grid>
        {message.text && (
          <Grid item xs={12}>
            <Alert severity={message.type}>{message.text}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} justifyContent="center">
              {Array.from({ length: 6 }).map((_, index) => (
                <Grid item key={index}>
                  <InputTextStyle
                    control={control}
                    name={`code${index + 1}`}
                    onChange={(e) => handleChange(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                </Grid>
              ))}
              <Grid container>
                <Grid item xs={12}>
                  <Text style={{ color: '#ffde59' }}>Please enter the code</Text>
                </Grid>
                <Grid item xs={12}>
                  <SendCodeAgainText>
                    <span>Didn't work?</span>
                    <SendCodeLink href="#" onClick={handleResendCode}>
                      send me another code
                    </SendCodeLink>
                  </SendCodeAgainText>
                </Grid>
              </Grid>
              <Grid item container justifyContent="center" sx={{ mt: 2 }}>
                <Grid item>
                  <MainButton variant="contained" type="submit">
                    Activate
                  </MainButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12}>
          <HelpLink text="Need help?" linkHref="#" linkText="Click here" />
        </Grid>
      </ActivateUserContent>
    </Grid>
  );
};

export default ActivateUser;
