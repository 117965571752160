import { StyledText } from './AuthTitle.style';
import { ReactNode } from 'react';

interface AuthTitleProps {
  children: ReactNode;
}

const AuthTitle = ({ children }: AuthTitleProps) => {
  return <StyledText>{children}</StyledText>;
};

export default AuthTitle;
