import styled from '@emotion/styled';
import InputText from '../../form/input-text/InputText';

export const UserName = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
`;

export const CheckInText = styled.span`
  font-size: 1.4rem;
  color: #808080;
  margin-left: 0.5rem;
`;

export const CheckInLink = styled.a`
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
  cursor: pointer;
  padding-inline-start: 0.3rem;
`;

export const InputTextStyled = styled(InputText)`
  background-color: transparent;
`;
