import imageCompression from 'browser-image-compression';

export const handleFileUpload = async (files: File[]): Promise<File[]> => {
  const maxSizeMB = 1; // Maximum file size after compression in MB
  const processedFiles: File[] = [];

  for (const file of files) {
    // Ensure the file is a File object before accessing properties
    if (file instanceof File) {
      if (file.type.startsWith('image/')) {
        const options = {
          maxSizeMB,
          maxWidthOrHeight: 1920,
        };

        try {
          const compressedFile = await imageCompression(file, options);
          processedFiles.push(compressedFile);
        } catch (error) {
          console.error(`Error compressing ${file.name}: ${error}`);
        }
      } else if (file.type.startsWith('video/')) {
        processedFiles.push(file);
      } else {
        console.warn(`${file.name} is not a supported media type.`);
      }
    } else {
      console.warn('Invalid file type');
    }
  }

  return processedFiles;
};

export default handleFileUpload;
