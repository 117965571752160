import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';

export const Card = styled.div`
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarSkeleton = styled(Skeleton)`
  border-radius: 50%;
  margin-right: 10px;
`;

export const Content = styled.div``;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CommentSection = styled.div``;
