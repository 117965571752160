import { Typography } from '@mui/material';
import Card from 'components/card/Card';
import { IUserClientDetails } from '../../../shared';

type UserDetails = Omit<IUserClientDetails, 'profileImage' | 'password' | 'coverImage' | 'id'>;

interface IUserInfoProps {
  userData: UserDetails;
  isLoading: boolean;
}

function UserInfo({ userData, isLoading }: IUserInfoProps) {
  return (
    <Card>
      <Typography variant="h2" fontWeight={600}>
        User Info
      </Typography>
      {isLoading ? (
        'Loading...'
      ) : userData ? (
        Object.entries(userData).map(([key, value]) => (
          <Typography key={key}>
            {key}: {value}
          </Typography>
        ))
      ) : (
        <Typography>No user data</Typography>
      )}
    </Card>
  );
}

export default UserInfo;
