import React from 'react';
import facebookIcon from '../../assets/icons/social/facebook.jpeg';

interface FacebookIconProps {
  width?: string;
  height?: string;
  className?: string;
  onClick?: () => void;
}

const FacebookIcon: React.FC<FacebookIconProps> = ({ width = '24px', height = '24px', className = '', onClick }) => (
  <img
    src={facebookIcon}
    alt="Facebook Icon"
    width={width}
    height={height}
    className={className}
    onClick={onClick}
    style={{ width, height }}
  />
);

export default FacebookIcon;
