import { forwardRef, memo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { UserAvatar } from 'components';
import { Grid } from '@mui/material';
import { InputTextStyled } from './AddComment.style';

interface AddCommentProps {
  onSubmit: (data: FieldValues) => void;
  isNestedReply?: boolean;
  replyToUserName?: string;
}

const AddComment = forwardRef<HTMLInputElement, AddCommentProps>(
  ({ replyToUserName, onSubmit, isNestedReply = false }, ref) => {
    const { control, handleSubmit, reset } = useForm<{ comment: string }>();

    return (
      <Grid container alignItems="center" sx={{ pb: 2 }}>
        <Grid item xs={1}>
          <UserAvatar />
        </Grid>
        <Grid item xs={11} alignItems="center">
          <form
            onSubmit={handleSubmit((data) => {
              onSubmit(data);
              reset();
            })}
          >
            <InputTextStyled
              name="comment"
              control={control}
              ref={ref}
              defaultValue={isNestedReply ? `@${replyToUserName} ` : ''}
              placeholder="Write a comment..."
              aria-label="Comment input"
            />
          </form>
        </Grid>
      </Grid>
    );
  }
);

export default memo(AddComment);
