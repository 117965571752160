import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Auth from '../pages/auth/Auth';
import PrivateRoute from './PrivateRoute';
import { AppRoutes as routes } from '../enums/AppRoutes';
import ResetPasswordRoute from './ResetPasswordRoute';
import Signin from '../pages/auth/sign-in/Signin';
import Signup from '../pages/auth/sign-up/Signup';
import ActivateUser from '../pages/auth/activate-user/ActivateUser';
import ForgotPassword from '../pages/auth/forgot-password/ForgotPassword';
import AuthSuccess from '../pages/auth/AuthSuccess';
import AuthFailure from '../pages/auth/AuthFailure';

const NotFound = lazy(() => import('../pages/not-found/NotFound'));
const NewFeeds = lazy(() => import('../pages/new-feeds/NewFeeds'));
const UserPage = lazy(() => import('../pages/user/UserPage'));

const AppRoutes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route
          path={routes.LoginRoute}
          element={
            <Auth>
              <Signin />
            </Auth>
          }
        />
        <Route
          path={routes.RegisterRoute}
          element={
            <Auth>
              <Signup />
            </Auth>
          }
        />
        <Route
          path={routes.ActivateUserRoute}
          element={
            <Auth>
              <ActivateUser />
            </Auth>
          }
        />
        <Route
          path={routes.ForgotPasswordRoute}
          element={
            <Auth>
              <ForgotPassword />
            </Auth>
          }
        />

        <Route path={routes.ResetPasswordRoute} element={<ResetPasswordRoute />} />

        {/* OAuth Success and Failure Routes */}
        <Route path={routes.AuthSuccessRoute} element={<AuthSuccess />} />
        <Route path={routes.AuthFailureRoute} element={<AuthFailure />} />

        {/* Protected Routes */}
        <Route
          path={routes.HomeRoute}
          element={
            <PrivateRoute>
              <Navigate to={routes.NewFeedsRoute} />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.NewFeedsRoute}
          element={
            <PrivateRoute>
              <NewFeeds />
            </PrivateRoute>
          }
        />

        <Route
          path={routes.UserRoute}
          element={
            <PrivateRoute>
              <UserPage />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
