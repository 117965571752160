import { TextStyled } from './HelpLink.style';

interface HelpLinkProps {
  text: string;
  linkText: string;
  linkHref: string;
  isUppercase?: boolean;
}

const HelpLink = ({ text, linkText, linkHref = '#', isUppercase = false }: HelpLinkProps) => {
  return (
    <TextStyled isUppercase={isUppercase}>
      {text} <a href={linkHref}>{linkText}</a>
    </TextStyled>
  );
};

export default HelpLink;
