import Cookies from 'js-cookie';

const cookieOptions = {
  secure: true,
  sameSite: 'Lax' as const,
};

export const getCookie = (cookieName: string): string | null => {
  return Cookies.get(cookieName) ?? null;
};

export const setCookie = (cookieName: string, value: string, days = 7): void => {
  Cookies.set(cookieName, value, { expires: days, ...cookieOptions });
};

export const deleteCookie = (cookieName: string): void => {
  Cookies.remove(cookieName, cookieOptions);
};

export const deleteAllCookies = (): void => {
  const allCookies = Cookies.get();
  Object.keys(allCookies).forEach((cookieName) => {
    Cookies.remove(cookieName, { path: '/', domain: window.location.hostname });
  });
};
