import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { AuthInputText } from '../Auth.style';

export const InputTextStyle = styled(AuthInputText)`
  .MuiInputBase-root {
    border-radius: 1.3rem;
    width: 5rem;
    height: 8rem;
    padding: 0;

    input {
      text-align: center;
      font-size: 3.92rem;
      font-weight: bold;
    }
  }
`;

export const UserEmail = styled(Typography)`
  font-size: 1.23rem;
  font-weight: bold;
  color: #ffde59;
`;

export const Text = styled(Typography)`
  font-size: 1.38rem;
  font-weight: bold;
  color: #ffde59;
  text-transform: uppercase;
  text-align: center;
`;

export const SendCodeAgainText = styled(Typography)`
  font-size: 1.33rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 3rem;
`;

export const SendCodeLink = styled.a`
  color: #fff;
  text-decoration: underline;
`;

export const ActivateUserContent = styled.div`
  width: 35rem;
`;
