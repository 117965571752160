import { ReactNode, useMemo } from 'react';
import { AuthWrapper, Container, Content, Instructions } from './Auth.style';
import { getInstructionsText } from './instructions-texts';
import { useAuthContext } from '../../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../enums';

interface AuthProps {
  children: ReactNode;
}

const routesToShowInstruction = [AppRoutes.LoginRoute, AppRoutes.RegisterRoute, AppRoutes.ActivateUserRoute];

const Auth = ({ children }: AuthProps) => {
  const location = useLocation();
  const { step } = useAuthContext();

  const instruction = useMemo(() => getInstructionsText(step), [step]);

  const shouldDisplayInstruction = useMemo(() => {
    return routesToShowInstruction.includes(location.pathname as AppRoutes);
  }, [location.pathname]);

  return (
    <AuthWrapper>
      <Container>
        {shouldDisplayInstruction && <Instructions>{instruction}</Instructions>}
        <Content>{children}</Content>
      </Container>
    </AuthWrapper>
  );
};

export default Auth;
