import { SxProps, Theme } from '@mui/material';

const mergeSx = (defaultSx: SxProps, customSx?: SxProps): SxProps => {
  return (theme: Theme) => {
    const defaultStyles = typeof defaultSx === 'function' ? defaultSx(theme) : defaultSx;
    const customStyles = typeof customSx === 'function' ? customSx(theme) : customSx;
    return {
      ...defaultStyles,
      ...customStyles,
    };
  };
};

export default mergeSx;
