import { Grid } from '@mui/material';
import { memo } from 'react';
import { DateCreated, UserName } from './PostTopBar.styled';
import UserAvatar from '../../../user/user-avatar/UserAvatar';
import PostOptions from '../../post-options/PostOptions';

interface PostTopBarProps {
  username: string;
  postCreatedAt: string;
  onDelete: () => void;
  onClickUserName: (username: string) => void;
  onEdit: () => void;
}

function PostTopBar({ username, onClickUserName, postCreatedAt, onDelete, onEdit }: PostTopBarProps) {
  return (
    <Grid container alignItems="center">
      <Grid item xs={1} container alignItems="center" onClick={() => onClickUserName(username)}>
        <UserAvatar />
      </Grid>
      <Grid xs item container direction="column" sx={{ lineHeight: 1 }}>
        <Grid item>
          <UserName onClick={() => onClickUserName(username)}>{username}</UserName>
        </Grid>
        <Grid item>
          <DateCreated>{postCreatedAt}</DateCreated>
        </Grid>
      </Grid>
      <Grid item>
        <PostOptions username={username} onDelete={onDelete} onEdit={onEdit} />
      </Grid>
    </Grid>
  );
}

export default memo(PostTopBar);
