import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useValidateResetTokenQuery } from '../store';
import CreateNewPassword from '../pages/auth/create-new-password/CreateNewPassword';
import { Alert } from '../components';
import { AlertSeverity } from '../components/alert/Alert';

const ResetPasswordRoute = () => {
  const { token } = useParams<{ token: string }>();

  const { isSuccess, error, isLoading } = useValidateResetTokenQuery({ token });

  useEffect(() => {
    console.log('ResetPasswordRoute mounted');
    console.log('Token from URL:', token);
  }, [token]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Alert severity={AlertSeverity.Error}>Invalid or expired token</Alert>;
  }

  if (isSuccess) {
    return <CreateNewPassword />;
  }

  return <Navigate to="/login" />;
};

export default ResetPasswordRoute;
