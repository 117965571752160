import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  CreateUserRequest,
  GetUserDataResponse,
  IResponse,
  UserLoginRequest,
  UserLoginResponse,
} from '../../shared';

import { createBaseQuery } from './FetchBase';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    signUp: builder.mutation<void, CreateUserRequest>({
      query: (user) => ({
        url: '/user/register',
        method: 'POST',
        body: user,
      }),
    }),
    signIn: builder.mutation<UserLoginRequest, UserLoginResponse>({
      query: (data) => ({
        url: '/user/login',
        method: 'POST',
        body: data,
      }),
    }),
    changePassword: builder.mutation<ChangePasswordResponse, ChangePasswordRequest>({
      query: (data) => ({
        url: '/user/change_password',
        method: 'POST',
        body: data,
      }),
    }),
    getUserData: builder.query<GetUserDataResponse, void>({
      query: () => ({
        url: '/user/getData',
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IResponse<GetUserDataResponse>) => response.data,
    }),
    activateUser: builder.mutation<void, { activationCode: string; email: string }>({
      query: (data) => ({
        url: '/user/activate',
        method: 'POST',
        body: data,
      }),
    }),
    resendActivationCode: builder.mutation<void, { email: string }>({
      query: (data) => ({
        url: '/user/resend-activation-code',
        method: 'POST',
        body: data,
      }),
    }),
    requestResetPassword: builder.mutation<void, { email: string }>({
      query: (data) => ({
        url: '/user/request-reset-password',
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: builder.mutation<void, { token: string; password: string }>({
      query: (data) => ({
        url: `/user/reset-password/${data.token}`,
        method: 'POST',
        body: { password: data.password },
      }),
    }),
    validateResetToken: builder.query<{ message: string }, { token: string }>({
      query: (data) => ({
        url: `/user/validate-reset-token/${data.token}`,
        method: 'GET',
      }),
    }),
    uploadProfileImage: builder.mutation<{ imageUrl: string }, FormData>({
      query: (formData) => ({
        url: '/user/upload-profile-image',
        method: 'POST',
        body: formData,
      }),
    }),
    uploadCoverImage: builder.mutation<{ imageUrl: string }, FormData>({
      query: (formData) => ({
        url: '/user/upload-cover-image',
        method: 'POST',
        body: formData,
      }),
    }),
    deleteProfileImage: builder.mutation<void, void>({
      query: () => ({
        url: '/user/delete-profile-image',
        method: 'DELETE',
      }),
    }),
    deleteCoverImage: builder.mutation<void, void>({
      query: () => ({
        url: '/user/delete-cover-image',
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useSignUpMutation,
  useSignInMutation,
  useChangePasswordMutation,
  useGetUserDataQuery,
  useActivateUserMutation,
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
  useValidateResetTokenQuery,
  useResendActivationCodeMutation,
  useUploadProfileImageMutation,
  useUploadCoverImageMutation,
  useDeleteProfileImageMutation,
  useDeleteCoverImageMutation,
} = userApi;
