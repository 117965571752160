import React, { forwardRef, useCallback } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { Autocomplete as AutocompleteField, Checkbox, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface AutocompleteProps<T extends FieldValues> {
  name: string;
  control: Control<T>;
  options: T[];
  placeholder: string;
  label: string;
  multiple?: boolean;
  onChange?: (value: T) => void;
  getOptionLabel: (option: T) => string;
  disableCloseOnSelect?: boolean;
}

const Autocomplete = forwardRef<HTMLInputElement | null, AutocompleteProps<any>>(
  (
    {
      name,
      control,
      disableCloseOnSelect = false,
      multiple = true,
      onChange,
      options,
      placeholder,
      label,
      getOptionLabel,
    },
    ref
  ) => {
    const { field } = useController({
      name,
      control,
      defaultValue: [],
    });

    const handleChange = useCallback(
      (value: any) => {
        if (onChange) {
          onChange(value);
        }
        field.onChange(value);
      },
      [field, onChange]
    );

    return (
      <AutocompleteField
        multiple={multiple}
        options={options}
        disableCloseOnSelect={disableCloseOnSelect}
        getOptionLabel={getOptionLabel}
        value={field.value}
        onChange={(_, value) => handleChange(value)}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            {multiple ? (
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            ) : null}
            {getOptionLabel(option)}
          </li>
        )}
        style={{ width: 500 }}
        renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} />}
      />
    );
  }
);

export default Autocomplete;
