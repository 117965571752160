import * as yup from 'yup';

export const createUserSchema = yup.object({
  username: yup.string().required('Username is required').min(2, 'Username must be at least 2 characters'),
  password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('password is required')
    .min(8, 'Password must be at least 8 characters'),
  email: yup.string().email().required('Email is required'),
  dateOfBirth: yup.date().required('Date of birth is required'),
});
