import { useCallback, useEffect, useRef, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAddReplyMutation } from 'store/services';
import { AppRoutes } from 'enums';
import { UserAvatar } from 'components';
import { useCookies, useTime } from 'hooks';
import { Grid, Typography } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import AddComment from '../add-comment/AddComment';
import { ActionButton, CommentWrapper, LikesAmount, TextWrapper, UserName, UserNameLink } from './Comment.style';
import { formatNumber } from '../../../utils';
import { AddReplyRequest } from '../../../shared';
import { useMutationRunner } from '../../../hooks';

interface Details {
  id: string;
  username: string;
  content: string;
  createdAt: Date;
}

interface CommentProps {
  isReply?: boolean;
  onReply?: (data: FieldValues) => void;
  onLike: (commentId: string, isReply: boolean) => void;
  comment?: Details;
  likesAmount: number;
  postId: string;
  username?: string;
}

function Comment({ comment, onLike, isReply = false, likesAmount, onReply, postId }: CommentProps) {
  const navigate = useNavigate();
  const [addReply] = useAddReplyMutation();
  const { getCookie } = useCookies();
  const username = getCookie('username');
  const commentRef = useRef<HTMLInputElement>(null);
  const [isNewReplyVisible, setIsNewReplyVisible] = useState<boolean>(false);
  const createdAt = useTime(comment?.createdAt);

  const navigateToUserPage = useCallback(
    (e: any) => {
      e.preventDefault();
      navigate(AppRoutes.UserRoute.replace(':username', e.target.value));
    },
    [navigate]
  );

  const addReplyMutationRunner = useMutationRunner<AddReplyRequest, void>(addReply, {});

  const handleAddReply = useCallback(
    async (content: FieldValues) => {
      if (comment?.id && username) {
        const request: AddReplyRequest = {
          username,
          postId,
          commentId: comment.id,
          content: content.comment,
        };
        await addReplyMutationRunner(request);
        setIsNewReplyVisible(false);
      }
    },
    [addReplyMutationRunner, comment?.id, postId, username]
  );

  const handleNewReplyField = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();
      setIsNewReplyVisible(!isNewReplyVisible);
    },
    [isNewReplyVisible]
  );

  const renderContentWithUsernames = useCallback((content: string) => {
    return content.split(' ').map((text, index) => {
      if (text.startsWith('@')) {
        return (
          <UserNameLink key={index} to={AppRoutes.UserRoute.replace(':username', text.slice(1))}>
            {text}
          </UserNameLink>
        );
      }

      return text + ' ';
    });
  }, []);

  useEffect(() => {
    if (isNewReplyVisible) {
      commentRef.current?.focus();
    }
  }, [isNewReplyVisible]);

  return (
    <CommentWrapper isReply={isReply}>
      <Grid container gap={0.3} alignItems="flex-start">
        <Grid item xs={1} container justifyContent="center" alignItems="center" onClick={navigateToUserPage}>
          <UserAvatar />
        </Grid>
        <Grid item container xs>
          <TextWrapper item container direction="column">
            <UserName onClick={() => navigateToUserPage(comment?.username)}>{comment?.username}</UserName>
            <Typography>{renderContentWithUsernames(comment?.content || '')}</Typography>
          </TextWrapper>
          <Grid item container justifyContent="flex-start" alignItems="center">
            <Grid item>
              <ActionButton variant="text" disableRipple onClick={() => onLike(comment?.id, isReply)}>
                Like
              </ActionButton>
            </Grid>
            {likesAmount > 0 ? (
              <Grid item>
                <LikesAmount>
                  <Typography fontSize="1.2rem">•</Typography>
                  <ThumbUpIcon sx={{ px: '.2rem' }} />
                  <Typography fontSize="1.2rem">{formatNumber(likesAmount)}</Typography>
                </LikesAmount>
              </Grid>
            ) : null}
            <Grid item>
              <Typography>|</Typography>
            </Grid>
            <Grid item>
              <ActionButton variant="text" disableRipple onClick={handleNewReplyField}>
                Reply
              </ActionButton>
            </Grid>
            {createdAt ? (
              <Grid item sx={{ fontSize: '1rem', pl: 1 }}>
                <span>{createdAt}</span>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {isNewReplyVisible && (
          <AddComment onSubmit={handleAddReply} ref={commentRef} isNestedReply replyToUserName={comment?.username} />
        )}
      </Grid>
    </CommentWrapper>
  );
}

export default Comment;
