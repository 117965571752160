import { AppRoutes } from './AppRoutes';

export enum AUTH_TYPES {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  ACTIVATE_USER = 'ACTIVATE_USER',
}

export const AUTH_TITLES = {
  [AppRoutes.LoginRoute]: 'Sign In',
  [AppRoutes.RegisterRoute]: 'Sign Up',
  [AppRoutes.ActivateUserRoute]: 'Activate User',
  [AppRoutes.ForgotPasswordRoute]: 'Forgot Password',
};

export enum ACTIVATION_TYPES {
  ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS',
  ACTIVATION_FAILURE = 'ACTIVATION_FAILURE',
  ACTIVATION_PENDING = 'ACTIVATION_PENDING',
}
