import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const StyledIconButton = styled('a')`
  width: 4rem;
  height: 4rem;
  display: block;

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`;

export const Title = styled(Typography)`
  font-size: 1.24rem;
  color: #fff;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: bold;
`;

export const IconsWrapper = styled('div')`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;
