import { useForm, UseFormProps, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';

function useYupValidationForm<T extends FieldValues>(schema: AnyObjectSchema, options?: UseFormProps<T>) {
  return useForm<T>({
    ...options,
    resolver: yupResolver(schema),
  });
}

export default useYupValidationForm;
