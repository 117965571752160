import React, { forwardRef, useCallback, useEffect } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { InputAdornment, SxProps } from '@mui/material';
import { FormHelperTextStyled, InputLabelStyled, InputTextStyled } from './InputText.style';

interface InputTextProps<T extends FieldValues> {
  name: string;
  label?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  type?: string;
  control: Control<T>;
  multiline?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  defaultValue?: string;
  sx?: SxProps;
  className?: string;
  noBorder?: boolean;
}

const InputText = forwardRef<HTMLInputElement, InputTextProps<any>>(
  (
    {
      name,
      label,
      type = 'text',
      control,
      multiline = false,
      placeholder,
      defaultValue,
      onChange,
      noBorder,
      value,
      startAdornment,
      endAdornment,
      sx,
      className,
      ...rest
    },
    ref
  ) => {
    const { field, fieldState } = useController({
      name,
      control,
      defaultValue: defaultValue ?? '',
    });

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange(e);
        if (onChange) {
          onChange(e);
        }
      },
      [field, onChange]
    );

    useEffect(() => {
      if (value !== undefined) {
        field.onChange(value);
      }
    }, [value, field]);

    return (
      <div className={className} style={{ position: 'relative', marginBottom: '1rem' }}>
        {label && <InputLabelStyled>{label}</InputLabelStyled>}
        <InputTextStyled
          {...field}
          noBorder={noBorder}
          inputRef={ref}
          sx={sx}
          fullWidth
          type={type}
          multiline={multiline}
          placeholder={placeholder}
          error={!!fieldState.error}
          startAdornment={
            startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : undefined
          }
          endAdornment={endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined}
          onChange={handleChange}
          {...rest}
        />
        {fieldState.error && <FormHelperTextStyled>{fieldState.error.message}</FormHelperTextStyled>}
      </div>
    );
  }
);

export default InputText;
