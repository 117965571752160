import { memo } from 'react';
import { Button, Grid } from '@mui/material';
import { ButtonsWrapper } from './PostActionsButtons.styled';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

interface PostActionsButtonsProps {
  onLike: () => void;
  onComment: () => void;
}

function PostActionsButtons({ onLike, onComment }: PostActionsButtonsProps) {
  return (
    <ButtonsWrapper container alignItems="center">
      <Grid item xs={6}>
        <Button variant="text" startIcon={<ThumbUpOutlinedIcon />} onClick={onLike} aria-label="Like">
          Like
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button variant="text" startIcon={<ChatBubbleOutlineOutlinedIcon />} onClick={onComment} aria-label="Comment">
          Comment
        </Button>
      </Grid>
    </ButtonsWrapper>
  );
}

export default memo(PostActionsButtons);
