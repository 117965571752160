import { useCallback, useState } from 'react';
import { useYupValidationForm } from 'hooks';
import { Grid } from '@mui/material';
import { forgotPasswordSchema } from '../../../shared';
import MainButton from '../../../components/button/MainButton';
import { useNavigate } from 'react-router-dom';
import { AuthSteps, useAuthContext } from '../../../contexts/AuthContext';
import FooterLink from '../components/footer-link/FooterLink';
import { AppRoutes } from '../../../enums';
import AuthTitle from '../components/auth-title/AuthTitle';
import { AuthInputText, Description } from '../Auth.style';
import { useRequestResetPasswordMutation } from '../../../store';
import HelpLink from '../components/help-link/HelpLink';
import { Alert } from '../../../components';

interface FormValues {
  email: string;
}

const ForgotPassword = () => {
  const { setStep } = useAuthContext();
  const [message, setMessage] = useState<string | null>(null);
  const [resetPassword] = useRequestResetPasswordMutation();
  const navigate = useNavigate();
  const { control, handleSubmit } = useYupValidationForm<FormValues>(forgotPasswordSchema);

  const onSubmit = useCallback(
    async (data: FormValues) => {
      await resetPassword({ email: data.email });
      setMessage('Email sent');
    },
    [resetPassword]
  );

  const handleBackToSignIn = useCallback(() => {
    setStep(AuthSteps.LOGIN);
    navigate(AppRoutes.LoginRoute);
  }, [navigate, setStep]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={2}>
        <AuthTitle>Forgot password?</AuthTitle>
        <Alert>{message}</Alert>
        <Description>Dont worry - enter your email and well send you link to reset your password.</Description>
        <Grid item>
          <AuthInputText name="email" control={control} label="email" />
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item>
            <MainButton type="submit" variant="contained">
              Submit
            </MainButton>
          </Grid>
        </Grid>
        <Grid item container justifyContent="center">
          <FooterLink text="Back to login" onClick={handleBackToSignIn} />
        </Grid>
        <Grid item>
          <HelpLink text="Need help?" linkHref="#" linkText="Click here" />
        </Grid>
      </Grid>
    </form>
  );
};

export default ForgotPassword;
