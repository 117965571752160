import { useCookies } from 'hooks';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthSuccess = () => {
  const navigate = useNavigate();
  const { setCookie } = useCookies();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const username = urlParams.get('username');

    if (token) {
      setCookie('token', token);
      setCookie('username', username);
      navigate('/new-feeds');
    } else {
      navigate('/auth/failure');
    }
  }, [navigate, setCookie]);

  return <div>Logging in...</div>;
};

export default AuthSuccess;
