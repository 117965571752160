import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const TextStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isUppercase',
})<{ isUppercase?: boolean }>(({ isUppercase }) => ({
  color: '#fff',
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: '1.33rem',
  textTransform: isUppercase ? 'uppercase' : 'none',
  paddingTop: '1rem',

  '& a': {
    color: '#fff',
    textDecoration: 'underline',
  },
}));
