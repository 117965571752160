import * as Yup from 'yup';

export const activateUserSchema = Yup.object().shape({
  code1: Yup.string()
    .matches(/^[0-9]$/, 'Must be a single digit')
    .required('This field is required'),
  code2: Yup.string()
    .matches(/^[0-9]$/, 'Must be a single digit')
    .required('This field is required'),
  code3: Yup.string()
    .matches(/^[0-9]$/, 'Must be a single digit')
    .required('This field is required'),
  code4: Yup.string()
    .matches(/^[0-9]$/, 'Must be a single digit')
    .required('This field is required'),
  code5: Yup.string()
    .matches(/^[0-9]$/, 'Must be a single digit')
    .required('This field is required'),
  code6: Yup.string()
    .matches(/^[0-9]$/, 'Must be a single digit')
    .required('This field is required'),
});
