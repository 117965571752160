import styled from '@emotion/styled';

export const PostImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PostVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const MediaContainer = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 200px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const MediaItemWrapper = styled.div<{ rowSpan: number; colSpan: number }>`
  position: relative;
  grid-row: span ${({ rowSpan }) => rowSpan};
  grid-column: span ${({ colSpan }) => colSpan};
  overflow: hidden;
  max-height: ${({ rowSpan }) => rowSpan * 200}px;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;
