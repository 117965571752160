export const SX = {
  LikesModal: {
    '& .MuiDialog-paper': {
      width: '30rem',
      maxWidth: '90vw',
      height: '30rem',
      borderRadius: '1rem',
      padding: '2rem',
      overflow: 'auto',
    },
  },
};
