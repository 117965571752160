import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const NavWrapper = styled(Grid)`
  background-color: #fff;
  padding: 2rem;
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  left: 0;
`;
