import styled from '@emotion/styled';
import { Box, IconButton } from '@mui/material';

export const PreviewWrapper = styled(Box)`
  padding: 1rem;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-block-end: 1rem;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-block-end: 1rem;
`;

export const DeleteButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;
