import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const LikesButton = styled(Typography)`
  font-size: 1.4rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
