import React, { useCallback, useState } from 'react';
import {
  CoverActions,
  CoverImageActions,
  CoverImg,
  ProfileActions,
  ProfileImageActions,
  ProfileImg,
  UserTopImagesWrapper,
} from './UserTopImages.style';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import profileImage from '../../../assets/images/user/profile.jpeg';
import coverBackground from '../../../assets/images/user/cover.jpeg';
import {
  useDeleteCoverImageMutation,
  useDeleteProfileImageMutation,
  useUploadCoverImageMutation,
  useUploadProfileImageMutation,
} from '../../../store';
import { IconButton } from '@mui/material';

interface IUserTopImagesProps {
  coverImage?: string;
  profileImage?: string;
}

const defaultCoverImage = coverBackground;
const defaultProfileImage = profileImage;

const isValidImage = (url?: string): boolean => {
  return !!url && (url.startsWith('http') || url.startsWith('/'));
};

const UserTopImages: React.FC<IUserTopImagesProps> = ({ coverImage, profileImage }) => {
  const [displayCoverImage, setDisplayCoverImage] = useState<string>(
    isValidImage(coverImage) ? coverImage! : defaultCoverImage
  );
  const [displayProfileImage, setDisplayProfileImage] = useState<string>(
    isValidImage(profileImage) ? profileImage! : defaultProfileImage
  );
  const [tempCoverImage, setTempCoverImage] = useState<string | null>(null);
  const [tempProfileImage, setTempProfileImage] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [uploadProfileImage] = useUploadProfileImageMutation();
  const [uploadCoverImage] = useUploadCoverImageMutation();
  const [deleteProfileImage] = useDeleteProfileImageMutation();
  const [deleteCoverImage] = useDeleteCoverImageMutation();

  const handleUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>, type: 'cover' | 'profile') => {
    if (event.target.files?.[0]) {
      const file = event.target.files[0];
      const previewUrl = URL.createObjectURL(file);

      setSelectedFile(file);
      if (type === 'cover') {
        setTempCoverImage(previewUrl);
      } else {
        setTempProfileImage(previewUrl);
      }
    }
  }, []);

  const handleSave = useCallback(
    async (type: 'cover' | 'profile') => {
      if (!selectedFile) return;

      const formData = new FormData();
      formData.append(type === 'cover' ? 'coverImage' : 'profileImage', selectedFile);

      try {
        if (type === 'cover') {
          const response = await uploadCoverImage(formData).unwrap();
          setDisplayCoverImage(response.imageUrl);
          setTempCoverImage(null);
        } else {
          const response = await uploadProfileImage(formData).unwrap();
          setDisplayProfileImage(response.imageUrl);
          setTempProfileImage(null);
        }
      } catch (error) {
        console.error(`Failed to upload ${type} image:`, error);
      } finally {
        URL.revokeObjectURL(tempCoverImage!);
        URL.revokeObjectURL(tempProfileImage!);
        setSelectedFile(null);
      }
    },
    [selectedFile, tempCoverImage, tempProfileImage, uploadCoverImage, uploadProfileImage]
  );

  const handleCancel = useCallback((type: 'cover' | 'profile') => {
    if (type === 'cover') {
      setTempCoverImage(null);
    } else {
      setTempProfileImage(null);
    }
    setSelectedFile(null);
  }, []);

  const handleDelete = useCallback(
    async (type: 'cover' | 'profile') => {
      try {
        if (type === 'cover') {
          await deleteCoverImage().unwrap();
          setDisplayCoverImage(defaultCoverImage);
        } else {
          await deleteProfileImage().unwrap();
          setDisplayProfileImage(defaultProfileImage);
        }
      } catch (error) {
        console.error(`Failed to delete ${type} image:`, error);
      }
    },
    [deleteCoverImage, deleteProfileImage]
  );

  return (
    <UserTopImagesWrapper>
      <CoverActions>
        <CoverImg src={tempCoverImage || displayCoverImage} alt="user cover image" />
        <CoverImageActions>
          <input type="file" onChange={(e) => handleUpload(e, 'cover')} style={{ display: 'none' }} id="coverInput" />
          <IconButton
            onClick={() => document.getElementById('coverInput')?.click()}
            aria-label="upload cover"
            size="large"
          >
            <UploadIcon fontSize="inherit" />
          </IconButton>
          {tempCoverImage ? (
            <>
              <IconButton onClick={() => handleSave('cover')} aria-label="save cover" size="large">
                <SaveIcon fontSize="inherit" />
              </IconButton>
              <IconButton onClick={() => handleCancel('cover')} aria-label="cancel cover" size="large">
                <CancelIcon fontSize="inherit" />
              </IconButton>
            </>
          ) : (
            <IconButton onClick={() => handleDelete('cover')} aria-label="delete cover" size="large">
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          )}
        </CoverImageActions>
      </CoverActions>
      <ProfileActions>
        <ProfileImg src={tempProfileImage || displayProfileImage} alt="user profile image" />
        <ProfileImageActions>
          <input
            type="file"
            onChange={(e) => handleUpload(e, 'profile')}
            style={{ display: 'none' }}
            id="profileInput"
          />
          <IconButton
            aria-label="upload profile"
            onClick={() => document.getElementById('profileInput')?.click()}
            sx={{ width: '3rem', height: '3rem' }}
          >
            <UploadIcon fontSize={'inherit'} />
          </IconButton>
          {tempProfileImage ? (
            <>
              <IconButton
                onClick={() => handleSave('profile')}
                aria-label="save profile"
                sx={{ width: '3rem', height: '3rem' }}
              >
                <SaveIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                onClick={() => handleCancel('profile')}
                aria-label="cancel profile"
                sx={{ width: '3rem', height: '3rem' }}
              >
                <CancelIcon fontSize="inherit" />
              </IconButton>
            </>
          ) : (
            <IconButton
              onClick={() => handleDelete('profile')}
              aria-label="delete profile"
              sx={{ width: '3rem', height: '3rem' }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          )}
        </ProfileImageActions>
      </ProfileActions>
    </UserTopImagesWrapper>
  );
};

export default UserTopImages;
