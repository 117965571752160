import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCookie, setCookie } from 'utils/cookies';

async function refreshAccessToken() {
  const refreshToken = getCookie('refreshToken');
  if (!refreshToken) {
    console.error('No refresh token available');
    window.location.href = '/login';
    return null;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/refreshtoken`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ refreshToken }),
    });

    if (!response.ok) {
      console.error('Failed to refresh token, redirecting to login');
      window.location.href = '/login';
      return null;
    }

    const data = await response.json();
    if (!data.accessToken) {
      console.error('No access token returned from refresh endpoint');
      return null;
    }

    // Update the token in the cookie
    setCookie('token', data.accessToken, 7); // Set for 7 days, adjust as needed

    // if the server responds with a new refresh token, update it as well
    if (data.refreshToken) {
      setCookie('refreshToken', data.refreshToken, 7); // Set for 7 days, adjust as needed
    }

    return data.accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    window.location.href = '/login';
    return null;
  }
}

export const createBaseQuery = () => {
  const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: async (headers) => {
      const token = getCookie('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });

  return async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error?.status === 401) {
      const newToken = await refreshAccessToken(); // This should now return the token or null
      if (newToken) {
        setCookie('token', newToken, 7); // Assuming you want the token to expire in 7 days
        args.headers.set('Authorization', `Bearer ${newToken}`);
        result = await baseQuery(args, api, extraOptions); // Retry the request with the new token
      } else {
        window.location.href = '/login';
      }
    }

    return result;
  };
};
