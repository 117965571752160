import { memo, useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCookies, useTime } from 'hooks';

import { PostClientDetails } from 'shared';
import { AppRoutes } from 'enums';
import Card from 'components/card/Card';
import { Box } from '@mui/material';
import AddComment from './add-comment/AddComment';
import PostDialog from './post-dialog/PostDialog';
import { usePost } from '../../hooks/usePost';
import PostActionsButtons from './components/post-actions-buttons/PostActionsButtons';
import PostComments from './components/post-comments/PostComments';
import PostContent from './components/post-content/PostContent';
import PostMedia from './components/post-media/PostMedia';
import PostRates from './components/post-rates/PostRates';
import PostTopBar from './components/post-top-bar/PostTopBar';

interface PostProps {
  post: PostClientDetails;
}

function Post({ post }: PostProps) {
  const { getCookie } = useCookies();
  const username = getCookie('username');
  const navigate = useNavigate();
  const postCreatedDate = useTime(post.createdAt);
  const { reset } = useForm();
  const addNewCommentRef = useRef<HTMLInputElement | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [likesModalOpen, setLikesModalOpen] = useState(false);
  const [isPostDialogOpen, setIsPostDialogOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);

  const {
    handleAddComment,
    handleDeletePost,
    handleEditPost,
    handleLikePost,
    handleReply,
    handleLikeComment,
    handleRemoveFile,
  } = usePost({
    post,
    username,
    selectedFiles,
    reset,
    setSelectedFiles,
    onClosePostDialog: () => setIsPostDialogOpen(false),
  });

  const isLongText = post.content?.length > 225;
  const toggleExpanded = useCallback(() => setIsExpanded(!isExpanded), [isExpanded]);
  const focusCommentInput = useCallback(() => addNewCommentRef.current?.focus(), []);
  const handlePostLikes = useCallback(() => setLikesModalOpen(true), []);

  const navigateToUserPage = useCallback(
    (username: string) => navigate(AppRoutes.UserRoute.replace(':username', username)),
    [navigate]
  );

  const handlePostDialog = useCallback(() => setIsPostDialogOpen((prev) => !prev), []);

  return (
    <Card sx={{ p: 0 }}>
      <Box sx={{ px: 2, pt: 2 }}>
        <PostTopBar
          username={post.username}
          postCreatedAt={postCreatedDate}
          onDelete={handleDeletePost}
          onClickUserName={navigateToUserPage}
          onEdit={handlePostDialog}
        />
        <PostContent
          isExpanded={isExpanded}
          content={post.content}
          isLongText={isLongText}
          onClickReadMore={toggleExpanded}
        />
      </Box>
      <PostMedia media={post.media} />
      <Box sx={{ px: 2, pt: 2 }}>
        <PostRates
          likes={post.likes}
          commentsAmount={post.comments.length}
          onLikesModal={handlePostLikes}
          onCloseLikesModal={() => setLikesModalOpen(false)}
          isLikesModal={likesModalOpen}
          onUserNameClick={navigateToUserPage}
        />
        <PostActionsButtons onComment={focusCommentInput} onLike={handleLikePost} />
        {post.comments.length > 0 && (
          <PostComments comments={post.comments} onLike={handleLikeComment} onReply={handleReply} postId={post.id} />
        )}
        <AddComment ref={addNewCommentRef} onSubmit={handleAddComment} />
      </Box>
      <PostDialog
        open={isPostDialogOpen}
        isEditMode
        username={username}
        content={post.content}
        onSubmit={handleEditPost}
        selectedFiles={selectedFiles}
        onClose={handlePostDialog}
        onRemoveFile={handleRemoveFile}
        setSelectedFiles={setSelectedFiles}
      />
    </Card>
  );
}

export default memo(Post);
