import React from 'react';
import { Skeleton } from '@mui/material';
import { AvatarSkeleton, Card, Content, Footer, Header } from './PostSkeleton.style';

const PostSkeleton = () => {
  return (
    <Card>
      <Header>
        <AvatarSkeleton height={80} width={50} />
        <div>
          <Skeleton width={100} height={20} />
          <Skeleton width={60} height={15} style={{ marginTop: 5 }} />
        </div>
      </Header>
      <Content>
        <Skeleton height={150} />
        <Skeleton height={30} width={`60%`} />
      </Content>
      <Footer>
        <Skeleton width={50} height={20} />
      </Footer>
    </Card>
  );
};

export default PostSkeleton;
