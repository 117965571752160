import React, { memo, useCallback } from 'react';
import { Grid } from '@mui/material';
import { Content, ReadMoreButton } from './PostContent.styled';

interface PostContentProps {
  isExpanded: boolean;
  content: string;
  isLongText: boolean;
  onClickReadMore: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function PostContent({ isExpanded, content, isLongText, onClickReadMore }: PostContentProps) {
  const shouldShowToggle = isLongText;

  const getTruncatedContent = useCallback(
    (text: string, limit: number) => {
      if (isExpanded) {
        return text;
      }
      return text.length > limit ? `${text.slice(0, limit)}...` : text;
    },
    [isExpanded]
  );

  return (
    <Grid item xs={12} container sx={{ py: 1.5 }}>
      <Content expanded={isExpanded}>{getTruncatedContent(content, 100)}</Content>
      {shouldShowToggle ? (
        <ReadMoreButton variant="text" onClick={onClickReadMore}>
          {isExpanded ? 'Read Less' : 'Read More'}
        </ReadMoreButton>
      ) : null}
    </Grid>
  );
}

export default memo(PostContent);
