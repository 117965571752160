export enum PostTypes {
  Soccer = 'Soccer',
  Basketball = 'Basketball',
  Baseball = 'Baseball',
  Football = 'Football',
  Tennis = 'Tennis',
  Golf = 'Golf',
  Cricket = 'Cricket',
  Hockey = 'Hockey',
  Swimming = 'Swimming',
  Athletics = 'Athletics',
  Cycling = 'Cycling',
  Boxing = 'Boxing',
  Gymnastics = 'Gymnastics',
  MartialArts = 'Martial Arts',
  Skiing = 'Skiing',
  Skateboarding = 'Skateboarding',
  Surfing = 'Surfing',
  Esports = 'Esports',
  Volleyball = 'Volleyball',
  TableTennis = 'Table Tennis',
  Badminton = 'Badminton',
  Rugby = 'Rugby',
  Snooker = 'Snooker',
  Darts = 'Darts',
  Bowling = 'Bowling',
  Archery = 'Archery',
  Fencing = 'Fencing',
  Rowing = 'Rowing',
  Sailing = 'Sailing',
  Equestrian = 'Equestrian',
}

export const PostTypesValues = Object.values(PostTypes);
