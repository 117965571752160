import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid } from '@mui/material';

import { DeleteButton, Img, PreviewWrapper, Video } from './MediaPreviewStyle';

interface PreviewProps {
  files: File[] | null;
  onRemoveFile: (index: number) => void;
}

function Preview({ files, onRemoveFile }: PreviewProps) {
  const renderContent = () => {
    return files?.map((file, index) => {
      const fileUrl = URL.createObjectURL(file);
      const isImage = file.type.startsWith('image/');
      const isVideo = file.type.startsWith('video/');

      return (
        <Grid item xs={12} sm={6} key={index}>
          <Box sx={{ position: 'relative' }}>
            {isImage && <Img src={fileUrl} alt={`file-${index}`} />}
            {isVideo && (
              <Video width="320" height="240" controls>
                <source src={fileUrl} type={file.type} />
                Your browser does not support the video tag.
              </Video>
            )}
            <DeleteButton onClick={() => onRemoveFile(index)}>
              <DeleteIcon />
            </DeleteButton>
          </Box>
        </Grid>
      );
    });
  };

  return (
    <PreviewWrapper>
      <Grid container spacing={2}>
        {renderContent()}
      </Grid>
    </PreviewWrapper>
  );
}

export default Preview;
