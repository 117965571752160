const convertToFormData = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item, item.name);
      });
    } else if (value !== null && value !== undefined) {
      formData.append(key, value);
    }
  });

  return formData;
};

export default convertToFormData;
