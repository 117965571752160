import React from 'react';

import { Button, DialogActions, DialogContent, DialogTitle, SxProps } from '@mui/material';

import { ModalStyle } from './ModalStyle';

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  title: string;
  onClose?: () => void;
  onSubmit?: () => void;
  sx?: SxProps;
  cancelButtonText?: string;
  submitButtonText?: string;
  className?: string;
  extraButtons?: React.ReactNode;
  isForm?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  open,
  title,
  onClose,
  onSubmit,
  sx,
  cancelButtonText = 'Cancel',
  submitButtonText = 'Save',
  className,
  extraButtons,
  isForm = false,
}) => {
  const handleSubmit = () => {
    if (onSubmit && isForm) {
      onSubmit();
    }
  };

  return (
    <ModalStyle open={open} onClose={onClose} className={className} sx={sx}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {extraButtons}
        <Button onClick={onClose}>{cancelButtonText}</Button>
        {isForm && (
          <Button type="submit" onClick={handleSubmit}>
            {submitButtonText}
          </Button>
        )}
      </DialogActions>
    </ModalStyle>
  );
};

export default Modal;
