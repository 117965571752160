import { Link, useNavigate } from 'react-router-dom';
import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';

import UserAvatar from 'components/user/user-avatar/UserAvatar';
import { useCookies } from 'hooks';
import { AppRoutes } from 'enums';
import { useCallback, useState } from 'react';
import SearchBar from '../search-bar/SearchBar';
import { NavWrapper } from './Navbar.style';
import UserMenuOptions from './user-menu-options/UserMenuOptions';
import UserNotifications from './user-notifications/UserNotifications';

function Navbar() {
  const { getCookie, deleteAllCookies } = useCookies();
  const username = getCookie('username');
  const navigate = useNavigate();
  const [userMenuOptionsAnchorEl, setUserMenuOptionsAnchorEl] = useState<null | HTMLElement>(null);
  const [userNotificationsAnchorEl, setUserNotificationsAnchorEl] = useState<null | HTMLElement>(null);
  const isUserNotificationsOpen = Boolean(userNotificationsAnchorEl);
  const isUserMenuOptionsOpen = Boolean(userMenuOptionsAnchorEl);

  const handleUserMenuOptionsClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setUserMenuOptionsAnchorEl(event.currentTarget);
  }, []);

  const handleCloseUserMenuOptions = useCallback(() => {
    setUserMenuOptionsAnchorEl(null);
  }, []);

  const toggleNotifications = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setUserNotificationsAnchorEl(event.currentTarget);
  }, []);

  const handleCloseNotifications = useCallback(() => {
    setUserNotificationsAnchorEl(null);
  }, []);

  const navigateToProfilePage = useCallback(() => {
    navigate(AppRoutes.UserRoute.replace(':username', username));
    handleCloseUserMenuOptions();
  }, [handleCloseUserMenuOptions, navigate, username]);

  const handleLogout = useCallback(() => {
    deleteAllCookies();
    navigate(AppRoutes.LoginRoute);
  }, [deleteAllCookies, navigate]);

  const navigateToNewfeedsPage = useCallback(() => {
    navigate(AppRoutes.NewFeedsRoute);
  }, [navigate]);

  return (
    <NavWrapper container justifyContent="space-between" alignItems="center">
      <Grid item xs={2}>
        <Link to={AppRoutes.NewFeedsRoute}>
          <img src="" alt="logo" width="30" height="30" />
        </Link>
      </Grid>
      <Grid item xs={3}>
        <SearchBar />
      </Grid>
      <Grid item xs={2} container justifyContent="space-between">
        <Grid item>
          <IconButton onClick={navigateToNewfeedsPage}>
            <PublicOutlinedIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={toggleNotifications}>
            <NotificationsNoneOutlinedIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Grid>
        <UserNotifications
          anchorEl={userNotificationsAnchorEl}
          open={isUserNotificationsOpen}
          handleClose={handleCloseNotifications}
          navigateToProfilePage={navigateToProfilePage}
          onLogout={handleLogout}
        />
        <Grid item xs={6} container>
          <Button
            onClick={handleUserMenuOptionsClick}
            aria-controls={isUserMenuOptionsOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isUserMenuOptionsOpen ? 'true' : undefined}
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <UserAvatar />
              <Typography fontSize="1.4rem">{username}</Typography>
            </Stack>
          </Button>
        </Grid>
        <UserMenuOptions
          anchorEl={userMenuOptionsAnchorEl}
          open={isUserMenuOptionsOpen}
          handleClose={handleCloseUserMenuOptions}
          navigateToProfilePage={navigateToProfilePage}
          onLogout={handleLogout}
        />
      </Grid>
    </NavWrapper>
  );
}

export default Navbar;
