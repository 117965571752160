import { useCallback, useState } from 'react';
import { useMutationRunner, useYupValidationForm } from 'hooks';
import { Grid } from '@mui/material';
import { resetPasswordSchema } from '../../../shared';
import MainButton from '../../../components/button/MainButton';
import AuthTitle from '../components/auth-title/AuthTitle';
import { useResetPasswordMutation } from '../../../store';
import { Alert } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthWrapper, Container, Content, Description, Instructions } from './CreateNewPassword.style';
import { instructionsTexts } from '../instructions-texts';
import { AuthSteps } from '../../../contexts/AuthContext';
import { AppRoutes as routes } from '../../../enums';
import { AuthInputText } from '../Auth.style';

interface FormValues {
  newPassword: string;
  confirmNewPassword: string;
}

const CreateNewPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [error, setErrorMessage] = useState<string | null>(null);
  const [createNewPasswordPassword] = useResetPasswordMutation();
  const { control, handleSubmit } = useYupValidationForm<FormValues>(resetPasswordSchema);

  const CreateNewPasswordMutationRunner = useMutationRunner<
    {
      token: string;
      password: string;
    },
    void
  >(createNewPasswordPassword, {
    onSuccess: () => navigate(routes.LoginRoute),
    onError: (error) => setErrorMessage(error.data.message),
  });

  const onSubmit = useCallback(
    async (data: FormValues) => {
      await CreateNewPasswordMutationRunner({ password: data.newPassword, token });
    },
    [CreateNewPasswordMutationRunner, token]
  );

  return (
    <AuthWrapper>
      <Container>
        <Instructions>{instructionsTexts[AuthSteps.CREATE_NEW_PASSWORD]}</Instructions>
        <Content>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={2}>
              <AuthTitle>create new password</AuthTitle>
              {error && (
                <Grid item sx={{ ml: 2 }}>
                  <Alert>{error}</Alert>
                </Grid>
              )}
              <Description>your password must include letters and numbers</Description>
              <Grid item>
                <AuthInputText type="password" name="newPassword" control={control} label="new password" />
              </Grid>
              <Grid item>
                <AuthInputText
                  type="password"
                  name="confirmNewPassword"
                  control={control}
                  label="confirm new password"
                />
              </Grid>
              <Grid item container justifyContent="center">
                <Grid item>
                  <MainButton type="submit" variant="contained">
                    set password
                  </MainButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Content>
      </Container>
    </AuthWrapper>
  );
};

export default CreateNewPassword;
