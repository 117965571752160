import { Button, styled, Typography, TypographyProps } from '@mui/material';

interface ContentProps extends TypographyProps {
  expanded?: boolean;
}

export const Content = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<ContentProps>(({ expanded }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: expanded ? 'none' : 3,
  WebkitBoxOrient: 'vertical',
  lineHeight: '1.6',
  maxHeight: expanded ? 'none' : '4.8em',
}));

export const ReadMoreButton = styled(Button)`
  font-size: 1rem;
  width: 9rem;
  height: 2rem;
  color: #4682b4;
`;
