import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const ButtonsWrapper = styled(Grid)`
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin: 1rem 0;
    padding: .5rem 0;

    .MuiButton-root {
        font-size: 1.2rem;
        height: 3rem;

        &:hover {
            border-radius: .4rem;
        }
    },
`;
