import { Alert, DateField } from 'components';
import { useMutationRunner, useYupValidationForm } from 'hooks';
import { useCallback, useState } from 'react';

import { useSignUpMutation } from 'store/services';

import { Grid } from '@mui/material';
import { CreateUserRequest, CreateUserResponse, createUserSchema, IResponse } from '../../../shared';
import SocialMediaButtons from '../components/social-media-buttons/SocialMediaButtons';
import MainButton from '../../../components/button/MainButton';
import AuthTitle from '../components/auth-title/AuthTitle';
import FooterLink from '../components/footer-link/FooterLink';
import { AuthSteps, useAuthContext } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../enums';
import { AuthInputText } from '../Auth.style';

function Signup() {
  const { setStep } = useAuthContext();
  const navigate = useNavigate();
  const [error, setErrorMessage] = useState<string | null>(null);
  const [signUpUser] = useSignUpMutation();
  const { control, handleSubmit } = useYupValidationForm<CreateUserRequest>(createUserSchema);

  const mutationRunner = useMutationRunner<CreateUserRequest, IResponse<CreateUserResponse>>(signUpUser, {
    onSuccess: ({ email }) => {
      setStep(AuthSteps.VERIFY);
      navigate(AppRoutes.ActivateUserRoute.replace(':email', email));
    },
    onError: (error) => {
      setErrorMessage(error.data.message);
    },
  });

  const onSubmit = useCallback(
    async (data: CreateUserRequest & { repeatPassword: string }) => {
      const { repeatPassword, ...rest } = data;
      await mutationRunner(rest);
    },
    [mutationRunner]
  );

  const handleBackToSignIn = useCallback(() => {
    setStep(AuthSteps.LOGIN);
    navigate(AppRoutes.LoginRoute);
  }, [navigate, setStep]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" gap={3}>
        <AuthTitle>New Account</AuthTitle>
        {error && (
          <Grid item sx={{ ml: 2 }}>
            <Alert>{error}</Alert>
          </Grid>
        )}
        <Grid container item rowSpacing={{ sm: 1 }}>
          <Grid item xs={12}>
            <AuthInputText name="username" label="Username" control={control} />
          </Grid>
          <Grid item xs={12}>
            <AuthInputText name="email" label="Email" control={control} />
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sm={5.5}>
              <AuthInputText name="password" label="Password" type="password" control={control} />
            </Grid>
            <Grid item xs={12} sm={5.5}>
              <AuthInputText name="repeatPassword" label="Repeat Password" type="password" control={control} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DateField name="dateOfBirth" label="Date of Birth" control={control} />
          </Grid>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item>
            <MainButton variant="contained" type="submit">
              Sign up
            </MainButton>
          </Grid>
        </Grid>
        <Grid item>
          <SocialMediaButtons text="Or sign up with" />
        </Grid>
        <Grid item container justifyContent="center" sx={{ mt: 6 }}>
          <FooterLink text="Back To Login" onClick={handleBackToSignIn} />
        </Grid>
      </Grid>
    </form>
  );
}

export default Signup;
