import styled from 'styled-components';

export const UserTopImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const CoverActions = styled.div`
  position: relative;
  width: 100%;
`;

export const CoverImg = styled.img`
  width: 100%;
  height: 30rem;
  object-fit: cover;
`;

export const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const ImageActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const CoverImageActions = styled(ImageActions)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const ProfileImageActions = styled(ImageActions)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    svg {
      color: #fff;
    }
  }
`;

export const ProfileActions = styled.div`
  position: absolute;
  bottom: -9rem;
  left: 42%;
  z-index: 1;
  width: 20rem;
  height: 20rem;

  &:hover ${ProfileImageActions} {
    opacity: 1;
    visibility: visible;
  }
`;
