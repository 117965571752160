import styled from '@emotion/styled';
import { Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface CommentWrapperProps {
  isReply: boolean;
}

export const CommentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isReply',
})<CommentWrapperProps>`
  position: relative;

  ${({ isReply }) => isReply && `margin-left: 5rem;`}
`;

export const TextWrapper = styled(Grid)`
  background-color: #f5f5f5;
  border-radius: 1.5rem;
  padding: 1rem 1.5rem;
  width: fit-content;
  max-width: 80%;
`;

export const UserName = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
`;

export const ActionButton = styled(Button)`
  font-size: 0.85rem;
  height: 3rem;

  &:hover {
    text-decoration: underline;
    background: none;
  }
`;

export const LikesAmount = styled(Typography)`
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
`;

export const UserNameLink = styled(Link)`
  cursor: pointer;
  color: #000;
  font-weight: 600;
  padding-right: 0.5rem;
  border: none;
  background: none;
  text-decoration: none;
`;
