import { UserImg } from './UserAvatar.style';
import UserIcon from '../../icons/UserIcon';

const UserAvatar = () => {
  return (
    <UserImg>
      <UserIcon />
    </UserImg>
  );
};

export default UserAvatar;
