import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ModalStyle = styled(Dialog)`
  .MuiDialogContent-root {
    width: 100%;
    max-width: 100%;
    min-width: 40rem;
    overflow: hidden;
    min-height: 30rem;
    max-height: 100%;
  }
`;

export const DialogTitleStyle = styled(DialogTitle)`
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e0e0e0;
`;

export const Actions = styled('div')`
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  border-top: 1px solid #e0e0e0;
`;

export const StaticButtons = styled('div')`
  display: flex;
  gap: 1rem;
`;

export const DialogContentStyle = styled(DialogContent)`
  padding: 1.5rem;
  min-height: 50vh;
  max-height: 70vh;
  overflow-y: auto;
`;
