export const SX = {
  Card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: 'fit-content',
    borderRadius: '1rem',
    boxShadow: '0 0 .5rem rgba(0,0,0,0.2)',
    transition: 'all 0.3s ease-in-out',
    backgroundColor: '#fff',
    marginBottom: '2rem',
    padding: '2rem',
  },
};
