import { useMemo } from 'react';

import moment from 'moment';

const useHumanizedTime = (date: Date) => {
  const humanizedTime = useMemo(() => {
    const postDate = moment(date);
    const now = moment();
    const timeDiff = moment.duration(now.diff(postDate));

    if (timeDiff.asSeconds() < 60) {
      return 'a few seconds ago';
    }
    if (timeDiff.asMinutes() < 60) {
      const minutes = Math.round(timeDiff.asMinutes());
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }
    if (timeDiff.asHours() < 24) {
      const hours = Math.round(timeDiff.asHours());
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    if (timeDiff.asDays() < 7) {
      const days = Math.round(timeDiff.asDays());
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
    return postDate.fromNow();
  }, [date]);

  return humanizedTime;
};

export default useHumanizedTime;
