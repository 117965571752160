export enum AppRoutes {
  HomeRoute = '/',
  LoginRoute = '/login',
  LogoutRoute = '/logout',
  ProfileRoute = '/profile',
  RegisterRoute = '/register',
  NewFeedsRoute = '/new-feeds',
  UserRoute = '/user/:username',
  ActivateUserRoute = '/activate-user/:email',
  ResetPasswordRoute = '/reset-password/:token',
  ForgotPasswordRoute = '/forgot-password',
  AuthSuccessRoute = '/auth/success',
  AuthFailureRoute = '/auth/failure',
}
