import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSignInMutation } from 'store/services';
import { useCookies, useMutationRunner, useYupValidationForm } from 'hooks';
import { Grid } from '@mui/material';
import { AppRoutes } from 'enums';
import { IResponse, loginUserSchema, UserLoginRequest, UserLoginResponse } from '../../../shared';
import SocialMediaButtons from '../components/social-media-buttons/SocialMediaButtons';
import MainButton from '../../../components/button/MainButton';
import AuthTitle from '../components/auth-title/AuthTitle';
import FooterLink from '../components/footer-link/FooterLink';
import { AuthSteps, useAuthContext } from '../../../contexts/AuthContext';
import HelpLink from '../components/help-link/HelpLink';
import { AuthInputText } from '../Auth.style';

interface UserSignInFormValues {
  username: string;
  password: string;
}

const Signin = () => {
  const { setCookie } = useCookies();
  const { setStep } = useAuthContext();
  const navigate = useNavigate();
  const [error, setErrorMessage] = useState<string | null>(null);
  const [signInUser] = useSignInMutation();
  const { control, handleSubmit } = useYupValidationForm<UserSignInFormValues>(loginUserSchema);

  const mutationRunner = useMutationRunner<UserLoginRequest, IResponse<UserLoginResponse>>(signInUser, {
    onSuccess: (values, response) => {
      if (response.data) {
        setCookie('token', response.data.token);
        setCookie('username', response.data.username);
        navigate(AppRoutes.NewFeedsRoute);
      }
    },
    onError: (error) => {
      if (error.data && error.data.message) {
      } else {
      }
    },
  });

  const onSubmit = useCallback(
    async (data: UserSignInFormValues) => {
      await mutationRunner(data);
    },
    [mutationRunner]
  );

  const handleBackToSignUp = useCallback(() => {
    setStep(AuthSteps.REGISTER);
    navigate(AppRoutes.RegisterRoute);
  }, [navigate, setStep]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <AuthTitle>Login</AuthTitle>
        <Grid item xs={12}>
          <AuthInputText name="username" label="Username" control={control} />
        </Grid>
        <Grid item xs={12} container alignItems="flex-start">
          <Grid item xs={12}>
            <AuthInputText name="password" label="Password" type="password" control={control} />
          </Grid>
          <Grid item sx={{ pl: 2 }}>
            <HelpLink
              text="Forgot password?"
              linkHref={AppRoutes.ForgotPasswordRoute}
              linkText="Click here"
              isUppercase
            />
          </Grid>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item>
            <MainButton variant="contained" type="submit">
              Let's Go!
            </MainButton>
          </Grid>
        </Grid>
        <Grid item container flexDirection="column" alignItems="center">
          <SocialMediaButtons text="Or with" />
        </Grid>
        <Grid item container justifyContent="center">
          <FooterLink text="Register" onClick={handleBackToSignUp} />
        </Grid>
      </Grid>
    </form>
  );
};

export default Signin;
