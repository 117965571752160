import styled from '@emotion/styled';

export const UserName = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
`;

export const DateCreated = styled.span`
  font-size: 1.2rem;
  color: #ccc;
`;
