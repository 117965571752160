import { Grid, Typography } from '@mui/material';

import { BoxStyled } from './FooterLink.style';
import LeftArrowIcon from '../../../../components/icons/LeftArrowIcon';

interface FooterLinkProps {
  text: string;
  onClick: () => void;
}

function FooterLink({ text, onClick }: FooterLinkProps) {
  return (
    <Grid container alignItems="center" justifyContent="center" gap={0.5} onClick={onClick} sx={{ cursor: 'pointer' }}>
      <LeftArrowIcon />
      <BoxStyled>
        <Typography>{text}</Typography>
      </BoxStyled>
    </Grid>
  );
}

export default FooterLink;
