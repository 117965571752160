import { useCallback } from 'react';
import Cookies from 'js-cookie';

interface SameSiteOptions {
  secure?: boolean;
  sameSite?: 'Lax' | 'Strict' | 'None';
}

const useCookies = () => {
  const getCookie = useCallback((cookieName: string) => {
    return Cookies.get(cookieName) ?? null;
  }, []);

  const setCookie = useCallback((cookieName: string, value: string, days?: number, options = {}) => {
    const secureOptions: SameSiteOptions = { secure: true, sameSite: 'Lax', ...options };
    Cookies.set(cookieName, value, { expires: days, ...secureOptions });
  }, []);

  const deleteCookie = useCallback((cookieName: string) => {
    Cookies.remove(cookieName, { secure: true, sameSite: 'Lax' });
  }, []);

  const deleteAllCookies = useCallback(() => {
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach((cookieName) => {
      Cookies.remove(cookieName, { path: '/', domain: window.location.hostname });
    });
  }, []);

  return { getCookie, setCookie, deleteCookie, deleteAllCookies };
};

export default useCookies;
