import { Grid } from '@mui/material';
import { Fragment, memo } from 'react';
import { CommentClientDetails } from 'shared';
import { FieldValues } from 'react-hook-form';
import Comment from '../../comment/Comment';
import Reply from '../../reply/Reply';

interface PostCommentsProps {
  comments: CommentClientDetails[];
  onLike: (commentId: string, isReply: boolean) => void;
  onReply: (data: FieldValues) => void;
  postId: string;
}

function PostComments({ comments, onLike, onReply, postId }: PostCommentsProps) {
  return (
    <Grid container>
      <Grid item xs={12}>
        {comments?.map((comment) => (
          <Fragment key={comment.id}>
            <Comment
              comment={comment}
              onLike={onLike}
              likesAmount={comment.likes.length}
              postId={postId}
              username={comment.username}
            />
            {comment.replies &&
              comment.replies.map((reply) => (
                <Reply
                  key={`reply-${reply.id}`}
                  reply={reply}
                  onLike={() => onLike(reply.id, true)}
                  onReply={onReply}
                  postId={postId}
                  replyToUserName={comment.username}
                />
              ))}
          </Fragment>
        ))}
      </Grid>
    </Grid>
  );
}

export default memo(PostComments);
