import { createApi } from '@reduxjs/toolkit/query/react';

import {
  AddCommentRequest,
  AddReplyRequest,
  CreatePostRequest,
  DeletePostRequest,
  EditPostRequest,
  GeneratePostWithAIRequest,
  GetAllPostsClientRequest,
  GetAllPostsResponse,
  GetUserPostsClientRequest,
  GetUserPostsResponse,
  IResponse,
  LikePostRequest,
  PostClientDetails,
} from 'shared';
import { createBaseQuery } from './FetchBase';
import { LikeCommentRequest } from '../../shared/protocols/comment/like-comment';

export const postApi = createApi({
  reducerPath: 'postApi',
  baseQuery: createBaseQuery(),
  tagTypes: ['getAllPosts', 'getUserPosts'],
  endpoints: (builder) => ({
    getAllPosts: builder.query<GetAllPostsResponse, GetAllPostsClientRequest>({
      query: (data) => ({
        url: '/post/getAll',
        method: 'POST',
        body: data,
        credentials: 'include',
      }),
      transformResponse: (response: IResponse<GetAllPostsResponse>) => response.data,
      providesTags: ['getAllPosts'],
    }),
    getUserPosts: builder.query<PostClientDetails[], GetUserPostsClientRequest>({
      query: (data) => ({
        url: '/post/getUserPosts',
        method: 'POST',
        body: data,
        credentials: 'include',
      }),
      transformResponse: (response: IResponse<GetUserPostsResponse>) => response.data.posts,
      providesTags: ['getUserPosts'],
    }),
    createPost: builder.mutation<void, CreatePostRequest>({
      query: (data) => ({
        url: '/post/create',
        method: 'POST',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['getAllPosts', 'getUserPosts'],
    }),
    addComment: builder.mutation<void, AddCommentRequest>({
      query: (data) => ({
        url: '/post/addComment',
        method: 'POST',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['getAllPosts', 'getUserPosts'],
    }),
    addReply: builder.mutation<void, AddReplyRequest>({
      query: (data) => ({
        url: '/post/replyToComment',
        method: 'POST',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['getAllPosts', 'getUserPosts'],
    }),
    editPost: builder.mutation<void, EditPostRequest>({
      query: (data) => ({
        url: '/post/edit',
        method: 'PATCH',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['getAllPosts', 'getUserPosts'],
    }),
    deletePost: builder.mutation<void, DeletePostRequest>({
      query: ({ postId }) => ({
        url: `/post/delete/${postId}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['getAllPosts', 'getUserPosts'],
    }),
    likePost: builder.mutation<void, LikePostRequest>({
      query: (postId) => ({
        url: '/post/like',
        method: 'POST',
        body: postId,
        credentials: 'include',
      }),
      invalidatesTags: ['getAllPosts', 'getUserPosts'],
    }),
    likeComment: builder.mutation<void, LikeCommentRequest>({
      query: (data) => ({
        url: '/post/likeComment',
        method: 'POST',
        body: data,
        credentials: 'include',
      }),
      invalidatesTags: ['getAllPosts', 'getUserPosts'],
    }),
    generatePostWithAI: builder.query<string, GeneratePostWithAIRequest>({
      query: (data) => ({
        url: '/post/generateTextFromAI',
        method: 'POST',
        body: data,
        credentials: 'include',
      }),
      transformResponse: (response: IResponse<string>) => response.data,
    }),
  }),
});

export const {
  useGetAllPostsQuery,
  useGetUserPostsQuery,
  useAddCommentMutation,
  useAddReplyMutation,
  useCreatePostMutation,
  useEditPostMutation,
  useDeletePostMutation,
  useLikePostMutation,
  useLikeCommentMutation,
  useGeneratePostWithAIQuery,
} = postApi;
