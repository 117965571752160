import React, { forwardRef } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { Box, SxProps } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, DatePickerProps as MUIDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { dateFieldSx, FormHelperTextStyled, InputLabelStyled } from './DateField.style';

interface DatePickerProps<TFieldValues extends FieldValues> extends MUIDatePickerProps<Date> {
  name: Extract<keyof TFieldValues, string>;
  label?: string;
  control: Control<TFieldValues>;
  sx?: SxProps;
  className?: string;
  helperText?: string;
  fullWidth?: boolean;
  placeholder?: string;
}

const DateField = forwardRef<HTMLDivElement, DatePickerProps<any>>(
  ({ name, label, control, sx, placeholder, className, helperText, fullWidth = true, ...props }, ref) => {
    const {
      field: { ref: inputRef, ...field },
      fieldState: { error },
    } = useController({ name, control });

    return (
      <Box className={className} sx={{ position: 'relative', marginBottom: '1rem' }}>
        {label && <InputLabelStyled>{label}</InputLabelStyled>}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            {...props}
            {...field}
            slotProps={{
              textField: {
                fullWidth,
                placeholder,
                inputRef,
                sx: dateFieldSx,
                className,
              },
            }}
            ref={ref}
          />
        </LocalizationProvider>
        {error && <FormHelperTextStyled>{error.message}</FormHelperTextStyled>}
      </Box>
    );
  }
);

export default DateField;
