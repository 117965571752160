import React, { createContext, useState, useContext, ReactNode } from 'react';

interface PostContextType {
  postData: any;
  postCheckIn: string | null;
  isCreatePostDialogOpen: boolean;
  isCheckInModalOpen: boolean;
  setPostData: (data: any) => void;
  setPostCheckIn: (location: string) => void;
  setIsCreatePostDialogOpen: (value: boolean) => void;
  setIsCheckInModalOpen: (value: boolean) => void;
}

const defaultContextData: PostContextType = {
  postData: {},
  postCheckIn: null,
  isCreatePostDialogOpen: false,
  isCheckInModalOpen: false,
  setPostData: () => {},
  setPostCheckIn: () => {},
  setIsCreatePostDialogOpen: () => {},
  setIsCheckInModalOpen: () => {},
};

export const PostContext = createContext<PostContextType>(defaultContextData);

interface Props {
  children: ReactNode;
}

export const PostProvider: React.FC<Props> = ({ children }) => {
  const [isCheckInModalOpen, setIsCheckInModalOpen] = useState(false);
  const [isCreatePostDialogOpen, setIsCreatePostDialogOpen] = useState(false);
  const [postCheckIn, setPostCheckIn] = useState<string | null>(null);
  const [postData, setPostData] = useState<any>({});

  return (
    <PostContext.Provider
      value={{
        postData,
        postCheckIn,
        isCheckInModalOpen,
        isCreatePostDialogOpen,
        setPostData,
        setPostCheckIn,
        setIsCheckInModalOpen,
        setIsCreatePostDialogOpen,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};

export const usePost = () => useContext(PostContext);
