import styled from '@emotion/styled';
import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';

export const FormControlStyled = styled(FormControl)`
  margin-bottom: 1rem;
`;

export const InputLabelStyled = styled(InputLabel)`
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-size: 1.26rem;
`;

export const SelectStyled = styled(Select)`
  .MuiSelect-select {
    font-size: 1.23rem;
    padding: 0.5rem;
    height: 4rem;
    display: flex;
    align-items: center;
  }

  .MuiOutlinedInput-root {
    border-radius: 2.9rem;
  }
`;

export const FormHelperTextStyle = styled(FormHelperText)`
  font-size: 1rem;
  color: ${(props) => props.theme.palette.error.main};
`;
