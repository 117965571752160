import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { MutationActionCreatorResult, MutationDefinition } from '@reduxjs/toolkit/query';
import { useCallback } from 'react';

export interface MutationRunnerOptions<RequestType, ResponseType> {
  prepareRequestData?: (values: RequestType) => RequestType;
  onSuccess?: (values: RequestType, data: ResponseType) => void;
  onError?: (error: any) => void;
  showMessageOnSuccess?: boolean;
  successMessage?: string;
}

export type UseMutationRunner<RequestType> = (values: RequestType) => Promise<boolean>;

const useMutationRunner = <RequestType, ResponseType>(
  mutation: MutationTrigger<any>,
  options?: MutationRunnerOptions<RequestType, ResponseType>
): UseMutationRunner<RequestType> => {
  return useCallback(
    async (values) => {
      const { prepareRequestData, onSuccess, onError } = options;

      try {
        const requestValues = prepareRequestData?.(values) ?? values;
        const response: ResponseType = await (
          mutation(requestValues) as MutationActionCreatorResult<MutationDefinition<any, any, any, any>>
        ).unwrap();

        if (onSuccess && response) {
          onSuccess(requestValues, response);
          return true;
        }
      } catch (error) {
        if (onError) {
          onError(error);
        }
      }

      return false;
    },
    [mutation, options]
  );
};

export default useMutationRunner;
