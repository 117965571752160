import { Menu, MenuItem } from '@mui/material';

interface UserNotificationsProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  onLogout: () => void;
  navigateToProfilePage: () => void;
}

function UserNotifications({ anchorEl, open, handleClose, navigateToProfilePage, onLogout }: UserNotificationsProps) {
  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <MenuItem onClick={navigateToProfilePage}>Profile</MenuItem>
      <MenuItem onClick={handleClose}>My account</MenuItem>
      <MenuItem onClick={onLogout}>Logout</MenuItem>
    </Menu>
  );
}

export default UserNotifications;
