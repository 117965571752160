import { AlertStyle } from './Alert.style';
import { ReactNode } from 'react';

export enum AlertSeverity {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
}

interface AlertProps {
  severity?: AlertSeverity;
  children: ReactNode;
}

function Alert({ severity = AlertSeverity.Error, children }: AlertProps) {
  return <AlertStyle severity={severity}>{children}</AlertStyle>;
}

export default Alert;
