import React from 'react';
import googleIcon from '../../assets/icons/social/google.jpeg';

interface GoogleIconProps {
  width?: string;
  height?: string;
  className?: string;
  onClick?: () => void;
}

const GoogleIcon: React.FC<GoogleIconProps> = ({ width = '24px', height = '24px', className = '', onClick }) => (
  <img
    src={googleIcon}
    alt="Google Icon"
    width={width}
    height={height}
    onClick={onClick}
    className={className}
    style={{ width, height }}
  />
);

export default GoogleIcon;
