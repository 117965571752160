import styled from '@emotion/styled';
import authDesktopBackground from '../../../assets/images/auth/auth-desktop-background.svg';
import authMobileBackground from '../../../assets/images/auth/auth-mobile-background.svg';
import { Typography } from '@mui/material';
import { breakpoints } from '../../../utils/breakpoints';

export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  padding: 5rem;
  background: url(${authDesktopBackground}) no-repeat center center;
  background-size: cover;

  @media (max-width: ${breakpoints.mobile}) {
    align-items: center;
    background: url(${authMobileBackground}) no-repeat center center;
    background-size: cover;
  }
`;

export const Container = styled.div`
  width: 50rem;
  max-width: 90vw;
  max-height: 90vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(35, 31, 32, 0.51);
  border-radius: 1rem;
  padding: 4rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  position: relative;
`;

export const Instructions = styled(Typography)`
  font-size: 1.83rem;
  color: #fff;
  text-align: center;
  padding-bottom: 1rem;
  font-weight: bold;
  width: 100%;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 2.94rem;
  }
`;

interface DescriptionProps {
  width?: string;
}

export const Description = styled(Typography)<DescriptionProps>`
  font-size: 1.23rem;
  font-weight: bold;
  color: #fff;
  margin: 1.5rem auto 0;
  width: ${(props) => props.width || '26rem'};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
