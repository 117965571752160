import React from 'react';
import appleIcon from '../../assets/icons/social/apple.jpeg';

interface AppleIconProps {
  width?: string;
  height?: string;
  className?: string;
  onClick?: () => void;
}

const AppleIcon: React.FC<AppleIconProps> = ({ width = '24px', height = '24px', className = '', onClick }) => (
  <img
    src={appleIcon}
    onClick={onClick}
    alt="Apple Icon"
    width={width}
    height={height}
    className={className}
    style={{ width, height }}
  />
);

export default AppleIcon;
