import { useCallback } from 'react';

import { useForm } from 'react-hook-form';

import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';

import InputText from '../form/input-text/InputText';

interface FormValues {
  search: string;
}

function SearchBar() {
  const { control, handleSubmit } = useForm<FormValues>();

  const onSubmit = useCallback(async (data: FormValues) => {
    console.log(data);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputText
        type="text"
        name="search"
        control={control}
        placeholder="Search for posts, users, and more..."
        endAdornment={
          <IconButton type="button" aria-label="search">
            <SearchIcon />
          </IconButton>
        }
      />
    </form>
  );
}

export default SearchBar;
