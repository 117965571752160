import styled from '@emotion/styled';
import InputText from 'components/form/input-text/InputText';

export const InputTextStyled = styled(InputText)`
  background-color: #f5f5f5;
  border-radius: 2.5rem;

  input {
    height: 3rem;
    padding: 0 1rem;
    font-size: 1.2rem;
  }

  fieldset {
    border-radius: 2.5rem;
    border: 0;
  }
`;
