import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { compressImage } from 'utils'; // Ensure this utility function is correctly imported
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { IconButton } from '@mui/material';

function UploadFile({ setSelectedFiles }) {
  const { control } = useForm();

  const checkFileSize = (files, maxSize) => {
    return files.every((file) => file.size <= maxSize * 1024 * 1024);
  };

  const handleOnChange = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const maxSize = 10; // 10MB max size for each file
      const files: File[] = Array.from(event.target.files);

      if (!checkFileSize(files, maxSize)) {
        alert('Some files exceed the maximum size limit.');
        event.target.value = '';
        return;
      }

      const compressedFiles = await compressImage(files);
      setSelectedFiles(compressedFiles);
    }
  };

  return (
    <Controller
      name="media"
      control={control}
      render={({ field }) => (
        <>
          <input
            id="media-upload"
            accept="image/*,video/*"
            style={{ display: 'none' }}
            type="file"
            multiple
            onChange={(event) => {
              handleOnChange(event).then((r) => field.onChange(r));
            }}
          />
          <label htmlFor="media-upload">
            <IconButton component="span">
              <InsertPhotoIcon />
            </IconButton>
          </label>
        </>
      )}
    />
  );
}

export default UploadFile;
