import { styled } from '@mui/system';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import FormHelperText from '@mui/material/FormHelperText';

interface CustomInputBaseProps extends InputBaseProps {
  noBorder?: boolean;
}

export const InputTextStyled = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'noBorder',
})<CustomInputBaseProps>(({ theme, noBorder }) => ({
  width: '100%',
  padding: '1rem',
  borderRadius: '2.5rem',
  border: noBorder ? 'none' : '1px solid #ccc',
  fontSize: '1.23rem',
  color: '#000',
  marginBottom: '0 !important',
  '.MuiInputBase-input': {
    padding: '0.5rem',
  },
}));

export const InputLabelStyled = styled('label')`
  margin-bottom: 0.5rem;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.26rem;
  color: #fff;
  padding: 0 2rem;
`;

export const FormHelperTextStyled = styled(FormHelperText)(({ theme }) => ({
  fontSize: '1.2rem',
  marginTop: '0.5rem',
  padding: '0 2rem',
  color: theme.palette.error.main,
}));
