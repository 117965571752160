import { memo } from 'react';
import { MediaClientDetails } from 'shared';
import { Grid, Typography } from '@mui/material';
import { MediaContainer, MediaItemWrapper, Overlay, PostImg, PostVideo } from './PostMedia.styled';

interface PostMediaProps {
  media: MediaClientDetails[];
}

function MediaItem({ data }: { data: MediaClientDetails }) {
  const { id, type, url } = data;

  if (type === 'image') {
    return <PostImg key={id} src={url} alt="Media content" />;
  } else if (type === 'video') {
    return <PostVideo key={id} controls src={url} />;
  }

  return null;
}

function PostMedia({ media }: PostMediaProps) {
  const calculateSpans = (index: number, total: number) => {
    if (total === 2) {
      return { rowSpan: 2, colSpan: 2 };
    } else if (total === 3) {
      if (index === 0) return { rowSpan: 2, colSpan: 2 };
      return { rowSpan: 1, colSpan: 1 };
    } else if (total === 4) {
      return { rowSpan: 1, colSpan: 1 };
    } else if (total >= 5) {
      if (index === 0) return { rowSpan: 2, colSpan: 2 };
      return { rowSpan: 1, colSpan: 1 };
    }
    return { rowSpan: 1, colSpan: 1 };
  };

  const renderGallery = (items: MediaClientDetails[]) => (
    <MediaContainer>
      {items.map((item, index) => {
        const { rowSpan, colSpan } = calculateSpans(index, items.length);
        return (
          <MediaItemWrapper key={item.id} rowSpan={rowSpan} colSpan={colSpan}>
            <MediaItem data={item} />
            {index === 4 && items.length > 5 && (
              <Overlay>
                <Typography variant="h4">+{items.length - 5}</Typography>
              </Overlay>
            )}
          </MediaItemWrapper>
        );
      })}
    </MediaContainer>
  );

  if (media.length === 1) {
    return (
      <Grid item xs={12}>
        <MediaItem data={media[0]} />
      </Grid>
    );
  }

  if (media.length >= 2 && media.length <= 5) {
    return renderGallery(media);
  }

  if (media.length > 5) {
    return renderGallery(media.slice(0, 5));
  }

  return null;
}

export default memo(PostMedia);
