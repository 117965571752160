import { useCallback, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';

import { Modal, UserAvatar } from 'components';
import { InputTextStyled } from './PostDialog.style';
import MediaPreview from '../preview/MediaPreview';
import UploadFile from '../upload-file/UploadFile';
import Autocomplete from '../../form/auto-complete/Authcomplete';
import { PostTypesValues } from '../../../shared';
import AIButton from '../ai-button/AIButton';
import AIGeneratePostModal from '../ai-generate-post-modal/AIGeneratePostModal';

interface AddPostDialogProps {
  open?: boolean;
  username: string;
  onClose: () => void;
  onSubmit: (data: FieldValues) => void;
  selectedFiles: File[] | null;
  content?: string;
  isEditMode?: boolean;
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[] | null>>;
  onRemoveFile: (index: number) => void;
}

function PostDialog({
  open = false,
  username,
  onClose,
  onSubmit,
  selectedFiles,
  content = '',
  setSelectedFiles,
  onRemoveFile,
  isEditMode = false,
}: AddPostDialogProps) {
  const [showAITextModal, setShowAITextModal] = useState(false);
  const [generatedAIContent, setGeneratedAIContent] = useState(content); // Initialize with content prop if any
  const { control, handleSubmit, reset } = useForm<FieldValues>();

  const dialogTitle = isEditMode ? 'Edit Post' : 'Create Post';

  const toggleAITextModal = useCallback(() => {
    setShowAITextModal((prev) => !prev);
  }, []);

  const handleFormSubmit = useCallback(
    (data) => {
      if (isEditMode) {
        onSubmit({ ...data, content: generatedAIContent });
      } else {
        data.content = generatedAIContent;
        onSubmit(data);
        reset();
        setSelectedFiles(null);
      }
    },
    [isEditMode, onSubmit, reset, setSelectedFiles, generatedAIContent]
  );

  const handleAITextModalSubmit = useCallback(
    (aiContent: string) => {
      setGeneratedAIContent(aiContent);
      toggleAITextModal();
    },
    [toggleAITextModal]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={dialogTitle}
      submitButtonText={isEditMode ? 'Update' : 'Post'}
      onSubmit={handleSubmit(handleFormSubmit)}
      extraButtons={
        <>
          <UploadFile setSelectedFiles={setSelectedFiles} />
          <AIButton onClick={toggleAITextModal} />
        </>
      }
      isForm
    >
      <Grid container direction="column">
        <Grid item container direction="row">
          <Grid item xs={1}>
            <UserAvatar />
          </Grid>
          <Grid item xs={11}>
            <Autocomplete
              name="postType"
              options={PostTypesValues}
              placeholder="choose your post type..."
              label="Post Type"
              multiple={false}
              control={control}
              getOptionLabel={(option) => option}
            />
          </Grid>
        </Grid>
        <Grid item>
          <InputTextStyled
            name="content"
            multiline
            noBorder
            value={generatedAIContent}
            onChange={(e) => setGeneratedAIContent(e.target.value)}
            control={control}
            placeholder="Write your post..."
          />
        </Grid>
        {selectedFiles?.length > 0 && <MediaPreview files={selectedFiles} onRemoveFile={onRemoveFile} />}
        {showAITextModal && (
          <AIGeneratePostModal
            open={showAITextModal}
            onSubmit={handleAITextModalSubmit}
            title="Generate Post with AI"
            onClose={toggleAITextModal}
          />
        )}
      </Grid>
    </Modal>
  );
}

export default PostDialog;
