import { Grid } from '@mui/material';
import { IconsWrapper, Title } from './SocialMediaButtons.style';
import { AppleIcon, FacebookIcon, GoogleIcon } from '../../../../components';
import { useCallback } from 'react';

interface SocialMediaProps {
  text: string;
}

const SocialMediaButtons = ({ text }: SocialMediaProps) => {
  const handleSocialLogin = useCallback((provider: string) => {
    const baseUrl = process.env.REACT_APP_BACKEND_URL;
    switch (provider) {
      case 'facebook':
        window.location.href = `${baseUrl}/user/auth/facebook`;
        break;
      case 'google':
        window.location.href = `${baseUrl}/user/auth/google`;
        break;
      case 'apple':
        window.location.href = `${baseUrl}/user/auth/apple`;
        break;
      default:
        break;
    }
  }, []);

  return (
    <Grid container direction="column" alignItems="center">
      <Title>{text}</Title>
      <IconsWrapper>
        <FacebookIcon onClick={() => handleSocialLogin('facebook')} />
        <GoogleIcon onClick={() => handleSocialLogin('google')} />
        <AppleIcon onClick={() => handleSocialLogin('apple')} />
      </IconsWrapper>
    </Grid>
  );
};

export default SocialMediaButtons;
