import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import Modal from '../../modal/Modal';
import InputText from '../../form/input-text/InputText';
import { useGeneratePostWithAIQuery } from '../../../store';

interface AIGeneratePostModalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (aiContent: string) => void;
}

function AIGeneratePostModal({ open, onSubmit, onClose, title }: AIGeneratePostModalProps) {
  const { control, handleSubmit, watch, reset } = useForm({ defaultValues: { AIContent: '' } });
  const [queryArgs, setQueryArgs] = useState<string>('');

  const prompt = watch('AIContent');

  const handleGeneratePost = useCallback(() => {
    if (prompt && prompt.trim() !== '') {
      setQueryArgs(prompt);
    }
  }, [prompt]);

  const args = useMemo(() => (queryArgs ? { prompt: queryArgs } : skipToken), [queryArgs]);

  const { data: postByAI, isLoading, isFetching } = useGeneratePostWithAIQuery(args);

  const handleFormSubmit = useCallback(() => {
    if (postByAI) {
      onSubmit(postByAI);
      reset();
    }
  }, [onSubmit, postByAI, reset]);

  return (
    <Modal open={open} title={title} onClose={onClose}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container direction="column" gap={2}>
          <Grid item>
            <InputText
              control={control}
              name="AIContent"
              label="AI Content"
              multiline
              placeholder="Write your instructions to generate a post!"
            />
          </Grid>

          {isLoading || isFetching ? (
            <Grid item container justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            <Grid item>
              <Typography style={{ maxHeight: '40rem', overflowY: 'auto' }}>
                {postByAI ?? 'No content generated yet.'}
              </Typography>
            </Grid>
          )}

          <Grid item container justifyContent="flex-end" gap={2}>
            <Button variant="contained" onClick={handleGeneratePost} disabled={isLoading || isFetching}>
              Generate Post
            </Button>
            <Button type="submit" disabled={isLoading || isFetching}>
              I like this!
            </Button>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
}

export default AIGeneratePostModal;
