import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function AuthFailure() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/auth/login');
  }, [navigate]);

  return <div>Authentication failed. Redirecting to login...</div>;
}

export default AuthFailure;
