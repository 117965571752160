import { useCallback, useContext, useRef, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useCreatePostMutation } from 'store/services';
import { Card, UserAvatar } from 'components';
import { useCookies, useMutationRunner } from 'hooks';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { Box, Grid, IconButton } from '@mui/material';

import { PostContext } from '../../../contexts';
import PostDialog from '../post-dialog/PostDialog';
import { InputTextStyled } from './CreatePost.style';

function CreatePost() {
  const [createPost] = useCreatePostMutation();
  const { getCookie } = useCookies();
  const username = getCookie('username');
  const { setIsCreatePostDialogOpen, isCreatePostDialogOpen } = useContext(PostContext);
  const { control, reset } = useForm<FieldValues>();
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleCloseModal = useCallback(() => {
    setIsCreatePostDialogOpen(false);
    setSelectedFiles(null);
  }, [setIsCreatePostDialogOpen]);

  const addPostMutationRunner = useMutationRunner<FormData, void>(createPost, {
    onSuccess: handleCloseModal,
  });

  const handleCreatePost = useCallback(
    async (data: FieldValues) => {
      if (!username || (!data.content && !selectedFiles && data.type)) {
        reset();
        handleCloseModal();
        return;
      }

      const formData = new FormData();
      formData.append('content', data.content || '');
      formData.append('type', data.postType || '');
      formData.append('username', username);

      selectedFiles?.forEach((file) => {
        formData.append('media', file);
      });

      await addPostMutationRunner(formData);
    },
    [addPostMutationRunner, handleCloseModal, reset, selectedFiles, username]
  );

  const showAddPostDialog = useCallback(() => {
    setIsCreatePostDialogOpen(true);
    inputRef.current?.focus();
  }, [setIsCreatePostDialogOpen]);

  const handleRemoveFile = useCallback(
    (index: number) => {
      if (selectedFiles) {
        const files = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(files);
      }
    },
    [selectedFiles]
  );

  return (
    <Card sx={{ p: 2 }}>
      <Grid container direction="column" onClick={showAddPostDialog}>
        <Grid item container alignItems="center" gap={1} sx={{ borderBlockEnd: '1px solid #ccc', pb: 2 }}>
          <Grid item xs={1}>
            <UserAvatar />
          </Grid>
          <Grid item xs container direction="column">
            <Grid item>
              <InputTextStyled
                name="addPost"
                multiline
                ref={inputRef}
                control={control}
                placeholder="Write anything you want..."
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center" onClick={showAddPostDialog}>
        <Grid item xs={12} sm={10} container alignItems="center">
          <label htmlFor="raised-button-file">
            <IconButton size="large" disableRipple component="span">
              <InsertPhotoIcon fontSize="inherit" />
              <Box sx={{ fontSize: '1.2rem' }}>Photo/Video</Box>
            </IconButton>
          </label>
        </Grid>
      </Grid>
      {isCreatePostDialogOpen ? (
        <PostDialog
          username={username}
          open={isCreatePostDialogOpen}
          onSubmit={handleCreatePost}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          onClose={handleCloseModal}
          onRemoveFile={handleRemoveFile}
        />
      ) : null}
    </Card>
  );
}

export default CreatePost;
