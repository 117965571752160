import { createTheme, SimplePaletteColorOptions, Theme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    button: SimplePaletteColorOptions;
    authInputText: SimplePaletteColorOptions;
  }

  interface PaletteOptions {
    button?: SimplePaletteColorOptions;
    authInputText?: SimplePaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    button: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    authInputText: true;
  }
}

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#8e2de2',
    },
    secondary: {
      main: '#4a00e0',
    },
    error: {
      main: '#FF3131',
    },
    success: {
      main: '#4caf50',
    },
    button: {
      main: 'rgb(239, 107, 72)',
      contrastText: '#ffffff',
      dark: 'rgb(209, 87, 52)',
    },
    authInputText: {
      main: '#414040',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '2rem',
          boxShadow: '0 .2rem .5rem rgba(0, 0, 0, 0.5)',
          color: '#ffffff',
          fontSize: '1.2rem',
          backgroundColor: '#8e2de2',
          transition: 'all .3s ease',
          height: '4rem',
          minWidth: '15rem',
          width: '100%',
          '&:hover': {
            backgroundColor: '#8e2de2',
            opacity: 0.9,
            boxShadow: '0 0.4rem 1rem rgba(0, 0, 0, 0.3)',
          },
        },
        text: {
          fontSize: '1.4rem',
          backgroundColor: 'transparent',
          borderRadius: '0',
          boxShadow: 'none',
          color: '#000',
          minWidth: 'auto',
          '&:hover': {
            backgroundColor: '#F5F5F5',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          fontFamily: 'Montserrat',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '1.4rem',
          fontFamily: 'Montserrat',
          '&::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          fontFamily: 'Montserrat',
        },
      },
    },
  },
});
