import { IReply } from 'shared';
import { FieldValues } from 'react-hook-form';
import Comment from '../comment/Comment';

interface CommentProps {
  onLike: (commentId: string, isReply: boolean) => void;
  onReply: (data: FieldValues) => void;
  reply: IReply;
  postId: string;
  replyToUserName?: string;
}

function Reply({ reply, onLike, onReply, postId, replyToUserName }: CommentProps) {
  const { id, username, content, createdAt } = reply;
  const data = { id, username, content, createdAt };

  return (
    <Comment
      onLike={onLike}
      comment={data}
      isReply
      likesAmount={reply.likesIds.length}
      onReply={onReply}
      postId={postId}
    />
  );
}

export default Reply;
