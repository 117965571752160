import React, { createContext, ReactNode, useContext, useState } from 'react';

export enum AuthSteps {
  REGISTER = 'register',
  LOGIN = 'login',
  VERIFY = 'verify',
  CREATE_NEW_PASSWORD = 'create_new_password',
}

interface AuthContextType {
  step: AuthSteps;
  setStep: (step: AuthSteps) => void;
}

const defaultContextData: AuthContextType = {
  step: AuthSteps.LOGIN,
  setStep: () => {},
};

export const AuthContext = createContext<AuthContextType>(defaultContextData);

interface Props {
  children: ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [step, setStep] = useState<AuthSteps>(AuthSteps.LOGIN);

  return <AuthContext.Provider value={{ step, setStep }}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
