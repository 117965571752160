import React, { useMemo, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';

interface PostOptionsProps {
  username: string;
  onEdit: (data: any) => void;
  onDelete: () => void;
}

function PostOptions({ username, onEdit, onDelete }: PostOptionsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const postOptions = useMemo(
    () => [
      {
        id: 1,
        title: 'Edit',
        onAction: onEdit,
      },
      {
        id: 2,
        title: 'Delete',
        onAction: onDelete,
      },
      // {
      //   id: 3,
      //   title: `Unfollow ${username}`,
      //   onAction: onUnfollowCreator,
      // },
    ],
    [onDelete, onEdit]
  );

  return (
    <Grid item xs={1} container alignItems="center">
      <IconButton
        aria-label="more"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ fontSize: '2.2rem' }}
      >
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {postOptions.map((option) => (
          <MenuItem key={option.id} onClick={option.onAction} sx={{ fontSize: '1.4rem' }}>
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
}

export default PostOptions;
