import React from 'react';
import { Box, SxProps } from '@mui/material';
import mergeSx from 'utils/mergeSX';
import { SX } from './Card.sx';

interface CardProps {
  children: React.ReactNode;
  sx?: SxProps;
}

function Card({ children, sx }: CardProps) {
  return <Box sx={mergeSx(SX.Card, sx)}>{children}</Box>;
}

export default Card;
