import { useCallback } from 'react';
import {
  useAddCommentMutation,
  useAddReplyMutation,
  useDeletePostMutation,
  useEditPostMutation,
  useLikeCommentMutation,
  useLikePostMutation,
} from 'store/services';
import { useMutationRunner } from 'hooks';
import { convertToFormData } from 'utils';
import { FieldValues, UseFormReset } from 'react-hook-form';
import {
  AddCommentRequest,
  AddReplyRequest,
  DeletePostRequest,
  EditPostRequest,
  LikePostRequest,
  PostClientDetails,
} from 'shared';
import { LikeCommentRequest } from '../shared/protocols/comment/like-comment';

interface UsePostActionsProps {
  post: PostClientDetails;
  username: string;
  selectedFiles: File[];
  setSelectedFiles: (files: File[]) => void;
  reset: UseFormReset<FieldValues>;
  onClosePostDialog: () => void;
}

export const usePost = ({
  post,
  username,
  selectedFiles,
  setSelectedFiles,
  reset,
  onClosePostDialog,
}: UsePostActionsProps) => {
  const [addComment] = useAddCommentMutation();
  const [deletePost] = useDeletePostMutation();
  const [editPost] = useEditPostMutation();
  const [likePost] = useLikePostMutation();
  const [likeComment] = useLikeCommentMutation();
  const [addReply] = useAddReplyMutation();

  /*
   * Add new comment
   */
  const addCommentMutationRunner = useMutationRunner<AddCommentRequest, void>(addComment, {
    onSuccess: () => {
      console.log('Comment added');
    },
  });

  const handleAddComment = useCallback(
    async (content: FieldValues) => {
      if (post?.id && username) {
        const request: AddCommentRequest = {
          username,
          postId: post.id,
          content: content.comment,
          media: selectedFiles ? convertToFormData(selectedFiles) : undefined,
        };
        await addCommentMutationRunner(request);
      }
    },
    [addCommentMutationRunner, post?.id, selectedFiles, username]
  );

  /*
   * Delete post
   */
  const deletePostMutationRunner = useMutationRunner<DeletePostRequest, void>(deletePost, {
    onSuccess: () => {
      console.log('Delete Post Successfully!');
    },
  });

  const handleDeletePost = useCallback(async () => {
    await deletePostMutationRunner({ postId: post.id });
  }, [deletePostMutationRunner, post.id]);

  /*
   * Edit post
   */
  const editPostMutationRunner = useMutationRunner<EditPostRequest, void>(editPost, {
    onSuccess: onClosePostDialog,
  });

  const handleEditPost = useCallback(
    async (data: FieldValues) => {
      if (!data.content && !selectedFiles) {
        reset();
        return;
      }

      const editPostRequest: EditPostRequest = {
        postId: post.id,
        content: data.content,
        ...(selectedFiles && { files: convertToFormData(selectedFiles) }),
      };

      await editPostMutationRunner(editPostRequest);
    },
    [editPostMutationRunner, post.id, reset, selectedFiles]
  );

  /*
   * Like post
   */
  const likePostMutationRunner = useMutationRunner<LikePostRequest, void>(likePost, {
    onSuccess: () => {
      console.log('Like Post Successfully!');
    },
  });

  const handleLikePost = useCallback(async () => {
    await likePostMutationRunner({ postId: post.id, username });
  }, [likePostMutationRunner, post.id, username]);

  /*
   * Like comment
   */
  const likeCommentMutationRunner = useMutationRunner<LikeCommentRequest, void>(likeComment, {
    onSuccess: () => {
      console.log('Like Comment Successfully!');
    },
  });

  const handleLikeComment = useCallback(
    async (commentId: string, isReply: boolean) => {
      await likeCommentMutationRunner({ postId: post.id, commentId, username, isReply });
    },
    [likeCommentMutationRunner, post.id, username]
  );

  const handleRemoveFile = useCallback(
    (index: number) => {
      if (selectedFiles) {
        const files = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(files);
      }
    },
    [selectedFiles, setSelectedFiles]
  );

  const addReplyMutationRunner = useMutationRunner<AddReplyRequest, void>(addReply, {
    onSuccess: () => {
      console.log('Reply added');
    },
  });

  const handleReply = useCallback(
    async (data: FieldValues) => {
      const request: AddReplyRequest = {
        username,
        postId: data.id,
        commentId: data.id,
        content: data.comment,
      };
      await addReplyMutationRunner(request);
    },
    [addReplyMutationRunner, username]
  );

  return {
    handleAddComment,
    handleDeletePost,
    handleEditPost,
    handleLikePost,
    handleLikeComment,
    handleRemoveFile,
    handleReply,
  };
};
