import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { breakpoints } from '../../../../utils/breakpoints';

export const StyledText = styled(Typography)`
  font-size: 3.92rem;
  color: #fff;
  text-align: center;
  padding-bottom: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  width: 100%;
  line-height: 1;
  border-bottom: 0.5rem solid ${({ theme }) => theme.palette.authInputText.main};

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 3rem;
  }
`;
