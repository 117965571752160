import { memo } from 'react';
import { Dialog, Grid, Typography } from '@mui/material';
import { ILike, LikeClientDetails } from 'shared';
import { SX } from './PostRates.sx';
import { LikesButton } from './PostRates.styled';

interface PostRatesProps {
  likes: LikeClientDetails[];
  isLikesModal: boolean;
  commentsAmount: number;
  onLikesModal: () => void;
  onUserNameClick: (username: string) => void;
  onCloseLikesModal: () => void;
}

function PostRates({
  likes,
  isLikesModal,
  onLikesModal,
  onCloseLikesModal,
  onUserNameClick,
  commentsAmount,
}: PostRatesProps) {
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <LikesButton onClick={onLikesModal}>{likes?.length} Likes</LikesButton>

        {isLikesModal && (
          <Dialog open={isLikesModal} onClose={onCloseLikesModal} sx={SX.LikesModal}>
            <Typography sx={{ mb: 3, fontSize: '1.6rem' }}>{likes.length} users like your post</Typography>

            {likes?.map((like: ILike) => (
              <Typography
                key={like.id}
                sx={{ pb: 1, cursor: 'pointer' }}
                onClick={() => onUserNameClick(like.username)}
              >
                {like.username}
              </Typography>
            ))}
          </Dialog>
        )}
      </Grid>
      <Grid item>
        <Typography sx={{ fontSize: '1.4rem' }}>{commentsAmount} Comments</Typography>
      </Grid>
    </Grid>
  );
}

export default memo(PostRates);
