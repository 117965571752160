import { AuthSteps } from '../../contexts/AuthContext';

export const instructionsTexts = {
  [AuthSteps.REGISTER]: "Register now and become part of the world's largest sports platform",
  [AuthSteps.LOGIN]: "Login to the world's largest sports platform",
  [AuthSteps.VERIFY]: "Only one step left to enter the world's largest sports platform",
  [AuthSteps.CREATE_NEW_PASSWORD]: "Only one step left to enter the world's largest sports platform",
};

export const getInstructionsText = (step: AuthSteps): string => {
  return instructionsTexts[step] || '';
};
