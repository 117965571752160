import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const BoxStyled = styled(Box)`
  text-align: center;

  p {
    font-weight: bold;
    font-size: 1.33rem;
    color: #fff;
    text-transform: uppercase;
  }
`;
